import React from 'react';
import DefaultModal from '../../../components/Modal/Modal';

const CHANGE_TAB_TEXT =
  'You are about to switch to another dosing tab.  Any data entered in the current tab will be discarded.  Are you sure?';

interface ModalProps {
  modalData: {
    show: boolean;
    value: number;
  };
  setModalData: React.Dispatch<
    React.SetStateAction<{
      show: boolean;
      value: number;
    }>
  >;
  setTabValue: React.Dispatch<React.SetStateAction<number>>;
}

const ChangeTabModal = ({ modalData, setModalData, setTabValue }: ModalProps) => {
  const { show, value } = modalData;

  const handleClose = () => setModalData({ ...modalData, show: false });
  const handleSubmit = () => {
    setModalData({ ...modalData, show: false });
    setTabValue(value);
  };

  return (
    <DefaultModal
      handleClose={handleClose}
      handleSubmit={handleSubmit}
      show={show}
      text={CHANGE_TAB_TEXT}
      cancelButtonText="Cancel"
      continueButtonText="Yes, Continue"
    />
  );
};

export default ChangeTabModal;
