import { createSlice } from '@reduxjs/toolkit';

export interface serviceTypeState {
  serviceType?: 'xyrem' | 'xywav';
}

export const initialState: serviceTypeState = {
  serviceType: undefined,
};

const serviceTypeSlice = createSlice({
  name: 'serviceType',
  initialState,
  reducers: {
    setServiceType(state, action) {
      state.serviceType = action.payload;
    },
  },
});

export const reducerServiceTypeActions = serviceTypeSlice.actions;
export default serviceTypeSlice.reducer;
