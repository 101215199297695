import { ErrorRemsInterface } from 'state/workflow/workflow.reducer';
import { EMAIL_TAKEN } from 'constants/validationMessages';
import { ERROR_INVALID_DEA, ERROR_VALIDATION_FAILED } from 'constants/errorCodes';

export type AlertError = ErrorRemsInterface | string | false | string[];

const setAlertMessage = (error: AlertError) => {
  const isErrorArr = Array.isArray(error) && error.length;
  const isErrorObj = !Array.isArray(error) && typeof error === 'object';

  if (isErrorArr && error[0] === EMAIL_TAKEN) {
    return EMAIL_TAKEN;
  }

  if (isErrorObj && error.code === ERROR_INVALID_DEA) {
    return error.message;
  }

  if (isErrorObj && error.code === ERROR_VALIDATION_FAILED) {
    if (error.errors) {
      return error.errors.map((error) => {
        const title = error.loc[0];
        const message = error.msg;
        if (title && message) {
          return (
            <div>
              Field: {title} - {message}
            </div>
          );
        }

        return 'Something went wrong';
      });
    }
  }

  return 'Something went wrong';
};

export default setAlertMessage;
