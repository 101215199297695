import { createAsyncThunk } from '@reduxjs/toolkit';
import { ConfirmationService, WorkflowService } from 'services/Resources';

import { workflowActions } from 'state/workflow';
import { authActions } from 'state/auth';
import { ConfirmationActions } from '.';

export const ConfirmationSubmitEmailToken = createAsyncThunk(
  'CONFIRMATION/submit',
  async (payload: any, { dispatch }) => {
    const { key } = payload;
    const options = {
      params: {
        key: key,
        enabled: undefined,
      },
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };

    await ConfirmationService.get(
      options,
      (res: any) => {
        dispatch(ConfirmationActions.setToken(res?.token));

        const {
          authorization_user_first_name,
          first_name,
          authorization_user_last_name,
          last_name,
          token,
          ...workflow
        } = res;

        dispatch(authActions.login({ access_token: token }));
        dispatch(ConfirmationActions.setFirstName(authorization_user_first_name || first_name));
        dispatch(ConfirmationActions.setLastName(authorization_user_last_name || last_name));

        WorkflowService.post(
          {
            params: {
              id: workflow.uuid,
              action: 'initialize',
            },
          },
          (response: any) => {
            dispatch(workflowActions.initWorkflow(response));
          },
          (err: any) => {
            dispatch(ConfirmationActions.setError(err));
          },
        );
      },
      (err: any) => {
        dispatch(ConfirmationActions.setError(err));
      },
    );
  },
);
