import { createSlice } from '@reduxjs/toolkit';
import { loadForms } from './forms.actions';

export type ValuesType = { [key: string]: any };

export type FormType = {
  values: {
    [key: string]: ValuesType;
  };
  tabValue: number;
  fullSignature: string;
  defaultLocationKey: string;
  notSavedItemList: string[];
};

export const initialState: FormType = {
  values: {},
  tabValue: 1,
  fullSignature: '',
  defaultLocationKey: '',
  notSavedItemList: [],
};

const formsSlice = createSlice({
  name: 'forms',
  initialState,
  reducers: {
    setFormValues(state, action) {
      const formId = action.payload.formId;
      const formValues = state.values[formId];
      state.values[formId] = { ...formValues, ...action.payload.values };
    },
    setLocationFormValues(state, action) {
      const formId = action.payload.formId;
      state.values[formId] = action.payload.values;
    },
    setTabValue(state, action) {
      state.tabValue = action.payload;
    },
    setFullSignature(state, action) {
      state.fullSignature = action.payload;
    },
    resetValues(state) {
      state.values = {};
      state.fullSignature = '';
      state.tabValue = 1;
      state.notSavedItemList = [];
    },
    setDefaultLocationKey(state, action) {
      state.defaultLocationKey = action.payload;
    },
    setNotSavedItemList(state, action) {
      state.notSavedItemList = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(loadForms, (state, action) => {
      state.values = action.payload;
    });
  },
});

export const reducerFormsActions = formsSlice.actions;
export default formsSlice.reducer;
