import { createSlice } from '@reduxjs/toolkit';

type section = {
  fields: object;
};

export interface actionInput {
  [key: string]: string | number;
}

export interface Workflow {
  displayStep: { id: string };
  forms: {
    [key: string]: {
      identifier: string;
      values: { [key: string]: string };
      sections: section[];
    };
  };
  form: any;
  status: string;
  stepId: number;
  steps: { label: string; type: string; method: string; action_name: string }[];
  nextStep: () => void;
  prevStep: () => void;
  setActionInput: (actionInput?: actionInput) => void;
  lastStepBeforeErrorClose: string;
  retryLatestActionStep: () => void;
}

export interface ErrorRemsInterface {
  status?: number;
  code: string;
  message?: string;
  title?: string;
  sub_title?: string;
  button_text?: string;
  errors?: { loc: string[]; msg: string; type: string; ctx: object }[];
}

export interface WorkflowState {
  workflow: null | Workflow;
  workflowState: typeof workflowDefaultState;
  date: string;
  qrVisibility: boolean;
  step: number;
  isLoading: boolean;
  location: string | null;
  locationForm: string | null;
  error: ErrorRemsInterface | string | false | string[];
}

const workflowDefaultState = {
  service: '',
  dateTime: '',
  location: {
    address: '',
    name: '',
  },
  error: false,
};

export const initialState: WorkflowState = {
  workflow: null,
  workflowState: workflowDefaultState,
  date: new Date().toISOString(),
  qrVisibility: false,
  step: 0,
  isLoading: true,
  location: null,
  locationForm: null,
  error: false,
};

const workflowSlice = createSlice({
  name: 'workflow',
  initialState,
  reducers: {
    reset(state) {
      state.workflow = null;
      state.workflowState = workflowDefaultState;
    },
    loadWorkflow(state, action) {
      state.workflow = action.payload;
    },
    update(state) {
      state.date = new Date().toISOString();
    },
    setWorkflowState(state, action) {
      state.workflowState = action.payload;
    },
    toggleQr(state) {
      state.qrVisibility = !state.qrVisibility;
    },
    setStep(state, action) {
      state.step = action.payload;
    },
    setLoading(state, action) {
      state.isLoading = action.payload;
    },
    select(state, action) {
      state.location = action.payload;
    },
    setError(state, action) {
      state.error = action.payload;
    },
    removeWorkflow(state) {
      state.workflow = null;
    },
    setLastStepBeforeErrorClose(state, action) {
      if (state.workflow) {
        state.workflow.lastStepBeforeErrorClose = action.payload;
      }
    },
    resetWorkflowStatus(state) {
      if (state.workflow) {
        state.workflow.status = '';
      }
    },
  },
});

export const reducerWorkflowActions = workflowSlice.actions;
export default workflowSlice.reducer;
