// IDENTIFIER
export const REMS_XYREM = 'rems_xyrem';
export const REMS_XYWAV = 'rems_xywav';
export const RCOPIA_LOGIN = 'rcopia_login';
export const REMS_UNSIGNED_PRESCRIPTION = 'rems_unsigned_prescription';
export const REMS_COMPLETED = 'rems_completed';
export const REMS_RESEND_IDP_INVITE = 'rems_resend_idp_invite';
export const REMS_REGISTRATION_INITIATED = 'rems_registration_initiated';
export const REMS_RESEND_IDP_INVITE_SUCCESS = 'rems_resend_idp_invite_success';
export const REMS_LAC_EMAIL_SUCCESS = 'rems_lac_email_success';
export const REMS_REGISTRATION = 'rems_registration';
export const REMS_COMPLETE_EPCS_REGISTRATION = 'rems_complete_epcs_registration';
export const REMS_REGISTRATION_LAC = 'rems_registration_lac';
export const REMS_LOCATION = 'rems_location_select';

//ACTIONS
export const SEND_PDF = 'send_pdf';
