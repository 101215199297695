import { useAppSelector } from '../../../hooks/useRedux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleXmark, faCheck } from '@fortawesome/free-solid-svg-icons';
import { errorSelector } from '../../../state/workflow/workflow.selectors';

interface SingleProgressProps {
  item: { label: string; id: number };
  activeStep: number;
}

const SingleProgress = ({ item, activeStep }: SingleProgressProps) => {
  const error = useAppSelector(errorSelector);

  const activeStepClassName = item.id === activeStep ? 'circle-active' : 'circle';
  const errorStepClassName = item.id === activeStep ? 'circle-error text-danger ' : 'circle';

  const renderCircle = () => {
    if (error && activeStep === item.id) {
      return (
        <FontAwesomeIcon
          icon={faCircleXmark}
          className={errorStepClassName}
        />
      );
    }
    if (activeStep > item.id) {
      return (
        <FontAwesomeIcon
          icon={faCheck}
          className="circle-done"
        />
      );
    } else {
      return <div className={activeStepClassName} />;
    }
  };

  return (
    <div className="col-3">
      <div className="circle-container">{renderCircle()}</div>
      <div className="text-info">Step {item.id + 1}</div>
      <div className="text-info fw-bolder">{item.label}</div>
    </div>
  );
};

export default SingleProgress;
