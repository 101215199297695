import { useLocation } from 'react-router-dom';
import NameHeader from 'components/nameHeader/NameHeader';
import FormWrapper from 'components/FormWrapper/FormWrapper';

const GenericError = () => {
  const location = useLocation();

  const disableButton = location.state?.disableButton;
  const errorSubTitle = location.state?.errorSubTitle;

  return (
    <>
      <NameHeader
        serviceType={false}
        disableLogoOnClick
        signOut
      />
      <div className="container mt-5 pt-5">
        <div className="row g-o">
          <FormWrapper
            disableButton={disableButton}
            errorSubTitle={errorSubTitle}
          />
        </div>
      </div>
    </>
  );
};

export default GenericError;
