import { createSlice } from '@reduxjs/toolkit';

interface CmeterState {
  cmeterStatus: [];
}

export const initialState: CmeterState = {
  cmeterStatus: [],
};

const cmeterSlice = createSlice({
  name: 'cmeter',
  initialState,
  reducers: {
    setCmeter(state, action) {
      state.cmeterStatus = action.payload;
    },
  },
});

export const reducerCmeterActions = cmeterSlice.actions;
export default cmeterSlice.reducer;
