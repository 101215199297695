import { FormEvent, useEffect, useState } from 'react';
import { Outlet, useNavigate, useOutlet } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { Alert } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import logo from 'assets/logo/jazz_logo_white.png';
import { useAppSelector, useAppDispatch } from 'hooks/useRedux';
import InfoBar from 'components/InfoBar/InfoBar';
import ErrorBadge from 'components/ErrorBadge/ErrorBadge';
import { authActions, authSelectors } from 'state/auth';
import { workflowActions } from 'state/workflow';
import useIdentifierNavigation from 'hooks/useIdentifierNavigation';
import { UserService } from 'services/Resources';
import './LoginView.scss';
import { INVALID_EMAIL } from 'constants/validationMessages';
import { emailRegex } from 'constants/validationRules';
import { ConfigurableHyperLinkSelector } from 'state/hyperlink/hyperlink.selectors';
import HealthCheck from 'containers/HealthCheck/HealthCheck';

const ERROR_MESSAGE = 'Email or password could not be validated. Please try again';

const LoginView = () => {
  const hyperlinks = useAppSelector(ConfigurableHyperLinkSelector);

  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const outlet = useOutlet();

  useIdentifierNavigation();

  useEffect(() => {
    window.onpopstate = () => {
      dispatch(authActions.logout());
      dispatch(workflowActions.reset());
      navigate('/', {
        replace: true,
      });
    };
  }, [window]);

  const {
    register,
    handleSubmit,
    formState: { errors },
    getValues,
    setValue,
  } = useForm({
    defaultValues: {
      email: '',
      password: '',
    },
  });

  const wrongCredentials = useAppSelector(authSelectors.wrongCredentialsSelector);
  const isLogin = useAppSelector(authSelectors.isLoginSelector);
  const newTabStatus = useAppSelector(authSelectors.newTabStatus);

  const [showMessage, setShowMessage] = useState(false);
  const [isLoader, setIsLoader] = useState(false);

  const email = getValues('email');
  const password = getValues('password');

  const setUserData = (r: any) => {
    dispatch(authActions.setUser(r));
    clearLoginForm();
  };

  const clearLoginForm = () => {
    setValue('email', '');
    setValue('password', '');
  };

  const getUserData = () => {
    UserService.get(
      {
        params: {
          enabled: undefined,
        },
      },
      setUserData,
      onError,
    );
    navigate('/workflow');
    dispatch(authActions.setWrongCredentials(false));
    setShowMessage(false);
  };

  useEffect(() => {
    if (wrongCredentials) {
      setShowMessage(true);
    }

    if (!wrongCredentials && isLogin) {
      getUserData();
    }
  }, [wrongCredentials, isLogin]);

  useEffect(() => {
    if (wrongCredentials) {
      dispatch(authActions.setWrongCredentials(false));
      setShowMessage(false);
    }
  }, [email, password]);

  const onError = () => {
    dispatch(workflowActions.setError(true));
  };

  const login = handleSubmit(async ({ email, password }) => {
    setIsLoader(true);
    await dispatch(authActions.loggingIn({ username: email, password: password }));
    setIsLoader(false);
  });

  const getRegistrationWorkflow = () => {
    const newTab = window.open('/register-information', '_blank');

    setTimeout(function () {
      try {
        if (!dispatch || !authActions || typeof authActions !== 'object') return;

        if (newTab === null || newTab.closed || newTab.closed === undefined) {
          dispatch(authActions.setNewTabStatus('ERROR'));
        } else {
          dispatch(authActions.setNewTabStatus('SUCCESS'));
        }
      } catch (e) {
        console.log('Error in opening new tab.');
        console.log(e);
      }
    }, 200);
  };

  const handleForgotPasswordClick = (event: FormEvent) => {
    event.preventDefault();
    navigate('/forgot-password');
  };

  const redBorderError = (formKey: 'email' | 'password') =>
    errors && errors[formKey] && 'border border-danger border-opacity-25 border-2';

  const validationBudge = (formKey: 'email' | 'password') =>
    errors &&
    errors[formKey] && (
      <ErrorBadge
        errors={errors}
        formKey={formKey}
      />
    );

  if (outlet) {
    return <Outlet />;
  }

  return (
    <div className="bg-rems d-flex">
      <div className="container pt-5 mt-5">
        <HealthCheck />
        {newTabStatus === 'SUCCESS' && (
          <Alert
            variant={'success'}
            className="text-center"
          >
            The registration workflow has opened in a new tab. If the new tab didn't automatically
            appear, please check your browser's open tabs to continue.
          </Alert>
        )}
        {newTabStatus === 'ERROR' && (
          <Alert
            variant={'danger'}
            className="text-center"
          >
            We were unable to open a new tab to launch your registration process. Please check your
            browser settings to allow pop-ups and try again, or contact support.
          </Alert>
        )}

        <div className="col-10 offset-1 col-md-6 offset-md-3 col-xs-12">
          <div className="card mb-2 shadow">
            <div className="card-header bg-info text-white fw-bolder text-center">
              <img
                src={logo}
                className="img-fluid align-text-middle"
              />
              <div className="pt-3">
                <h5>
                  <span className="fw-bold">XYWAV</span> and{' '}
                  <span className="fw-bold">XYREM REMS</span> ePrescribing Login
                </h5>
              </div>
            </div>
            <form onSubmit={login}>
              <div className="card-body p-0">
                <div className="row px-3 py-1">
                  <div className="pt-3">
                    {showMessage && <Alert variant={'danger'}>{ERROR_MESSAGE}</Alert>}
                  </div>
                  <div className="col-12">
                    <label>
                      Email<span className="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      id="email-input-login"
                      className={`form-control ${redBorderError('email')}`}
                      {...register('email', {
                        required: {
                          value: true,
                          message: 'Email is required',
                        },
                        pattern: {
                          value: emailRegex,
                          message: INVALID_EMAIL,
                        },
                      })}
                    />
                    {validationBudge('email')}
                  </div>
                  <div className="col-12">
                    <label>
                      Password<span className="text-danger">*</span>
                    </label>
                    <input
                      type="password"
                      id="password-input-login"
                      className={`form-control ${redBorderError('password')}`}
                      {...register('password', {
                        required: {
                          value: true,
                          message: 'Password is required',
                        },
                      })}
                    />
                    {validationBudge('password')}
                  </div>
                  <div className="col-12 small d-flex flex-row justify-content-between">
                    <div className="col-9">
                      For XYWAV and XYREM REMS certified prescribers only. <br></br>Complete the
                      certification by clicking{' '}
                      <a
                        id="complete-certification-here-anchor-login"
                        href={hyperlinks['configurable_hyperlink_1'] || ''}
                        target="_blank"
                      >
                        here
                      </a>
                    </div>
                    <div className="">
                      <a
                        id="forgot-password-anchor-login"
                        href="#"
                        onClick={handleForgotPasswordClick}
                      >
                        Forgot Password?
                      </a>
                    </div>
                  </div>
                </div>
              </div>

              <div className="row p-3 text-center">
                <div className="col-12">
                  {!isLoader ? (
                    <a
                      href="ProviderRegistration - Step 2_IDP.html"
                      className="text-decoration-none"
                    >
                      <button
                        id="login-button"
                        className="btn btn-primary"
                      >
                        Login
                      </button>
                    </a>
                  ) : (
                    <button
                      id="login-button"
                      className="btn btn-primary"
                      disabled={true}
                    >
                      Login &nbsp;
                      <FontAwesomeIcon
                        icon={faSpinner}
                        color="white"
                        spinPulse={true}
                      />
                    </button>
                  )}
                </div>

                <div className="col-12 mt-2">
                  New User?{' '}
                  <a
                    id="register-now-anchor-login"
                    className="link"
                    onClick={getRegistrationWorkflow}
                  >
                    Register Now
                  </a>
                </div>
              </div>
            </form>
            <InfoBar>
              Having trouble logging in?{' '}
              <a
                id="chat-with-us-anchor-login"
                href={hyperlinks['support_chat_url']}
                target="_blank"
              >
                Chat with us
              </a>{' '}
              or contact support at (855) 863-1355.
            </InfoBar>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LoginView;
