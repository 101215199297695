import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import React from 'react';
import ReactDOM from 'react-dom/client';
import reportWebVitals from './reportWebVitals';
import { Provider } from 'react-redux';
import store from './state/store';
import App from './App';
import './index.css';
import errorLogger from './utils/errorLogger';
import { OneTabEnforcer } from 'containers/OneTabEnforcer/OneTabEnforcer';
import OneTabErrorComponent from 'components/OneTabError/OneTabErrorComponent';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

errorLogger.init();

root.render(
  <Provider store={store}>
    <OneTabEnforcer
      appName="md.tial.rems"
      OneTabViolationComponent={OneTabErrorComponent}
    >
      <App />
    </OneTabEnforcer>
  </Provider>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
