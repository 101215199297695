import { useEffect, useState } from 'react';
import { useAppSelector } from 'hooks/useRedux';
import { serviceTypeSelectors } from 'state/serviceType/index';
import NameHeader from 'components/nameHeader/NameHeader';
import ErrorComponent from 'components/ErrorComponent/ErrorComponent';
import RcopiaLoginForm from 'forms/RcopiaLoginForm/RcopiaLoginForm';
import { workflowSelectors } from 'state/workflow';
import LACConfirmation from 'forms/Confirmation/LAC/LACConfirmation';
import TOUConfirmation from 'forms/Confirmation/TOU/TOUConfirmation';
import UnsignedConfirmation from 'forms/Confirmation/UnsignedConfirmation';
import CompleteConfirmation from 'forms/Confirmation/CompleteConfirmation';

const getWindowSize = () => {
  const { innerWidth, innerHeight } = window;
  return { innerWidth, innerHeight };
};

function InitConfirmation() {
  const form = useAppSelector(workflowSelectors.workflowForm);

  const serviceType = useAppSelector(serviceTypeSelectors.unreadServiceTypeSelector);

  const identifier = form?.identifier;

  const [windowSize, setWindowSize] = useState(getWindowSize());

  const { labelText } = useAppSelector((state) => state.confirmation.staticText);

  useEffect(() => {
    const handleWindowResize = () => {
      setWindowSize(getWindowSize());
    };
    window.addEventListener('resize', handleWindowResize);

    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  }, []);

  const smallScreenSupport = windowSize.innerWidth >= 1200 ? 'container' : 'container-fluid';

  if (!identifier) {
    return null;
  }

  const renderForm = () => {
    switch (identifier) {
      case 'rems_lac_authorization_token_confirmation':
        return <LACConfirmation />;
      case 'rems_rcopia_tou_token_confirmation':
        return <TOUConfirmation />;
      case 'rcopia_login':
        return (
          <RcopiaLoginForm
            form={form}
            text={labelText}
          />
        );
      case 'rems_uncompleted_lac':
        return <UnsignedConfirmation text={labelText} />;
      case 'rems_lac_authorization_success':
      case 'rems_rcopia_tou_completed':
        return <CompleteConfirmation text={labelText} />;

      default:
        return <ErrorComponent />;
    }
  };

  return (
    <>
      <NameHeader
        disableLogoOnClick
        serviceType={serviceType}
        remsFlow={true}
      />

      <div className={`${smallScreenSupport} mt-5`}>{renderForm()}</div>
    </>
  );
}

export default InitConfirmation;
