import { useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilePrescription } from '@fortawesome/free-solid-svg-icons';
import { faCheckCircle } from '@fortawesome/free-regular-svg-icons';
import { workflowActions, workflowSelectors } from 'state/workflow';
import { progressBarActions } from 'state/progressBar';
import { useAppDispatch, useAppSelector } from 'hooks/useRedux';
import FormWrapper from 'components/FormWrapper/FormWrapper';

const CompleteWorkflow = () => {
  const dispatch = useAppDispatch();

  const form = useAppSelector(workflowSelectors.workflowForm);

  const identifier = form?.identifier;

  useEffect(() => {
    dispatch(workflowActions.workflowNextStep());
    dispatch(progressBarActions.nextStep());
  }, []);

  useEffect(() => {
    dispatch(workflowActions.setLoading(false));
  }, [identifier]);

  const handleClick = () => {
    dispatch(workflowActions.initializeWorkflow('rems'));
  };

  return (
    <>
      <FormWrapper text="Complete Prescription">
        <div className="row p-3 text-center">
          <div className="col-12">
            <div className="fa-6x">
              <FontAwesomeIcon
                icon={faCheckCircle}
                className="text-success"
              />
            </div>
            <h5 className="text-success">Prescription Successfully Signed</h5>
            <a
              href="/"
              className="btn btn-primary m-3"
              onClick={handleClick}
            >
              <FontAwesomeIcon
                icon={faFilePrescription}
                color="white"
              />
              &nbsp;Write New Prescription
            </a>
          </div>
        </div>
      </FormWrapper>
    </>
  );
};

export default CompleteWorkflow;
