import { PayloadAction, createSlice } from '@reduxjs/toolkit';

type StaticText = {
  labelText: string;
  headerSuccessText: string;
  headerErrorText: string;
  buttonText: string;
  expiredTokenErrorText: string;
};
export interface ConfirmationState {
  token: string;
  firstName: string;
  lastName: string;
  workflow: any;
  error: any;
  name: string;
  staticText: StaticText;
}

export const initialState: ConfirmationState = {
  token: '',
  firstName: '',
  lastName: '',
  workflow: {},
  error: null,
  name: '',
  staticText: {
    labelText: '',
    headerSuccessText: '',
    headerErrorText: '',
    buttonText: '',
    expiredTokenErrorText: '',
  },
};

const confirmationSlice = createSlice({
  name: 'confirmation',
  initialState,
  reducers: {
    setToken: (state, action) => {
      state.token = action.payload;
    },
    setFirstName: (state, action) => {
      state.firstName = action.payload;
    },
    setLastName: (state, action) => {
      state.lastName = action.payload;
    },
    setWorkflow: (state, action) => {
      state.workflow = action.payload;
    },
    setError: (state, action) => {
      state.error = action.payload;
    },
    setName: (state, action) => {
      state.name = action.payload;
    },
    setStaticText: (state, action: PayloadAction<StaticText>) => {
      state.staticText = action.payload;
    },
  },
});

export const reducerConfirmationActions = confirmationSlice.actions;
export default confirmationSlice.reducer;
