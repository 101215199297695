import { useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilePrescription } from '@fortawesome/free-solid-svg-icons';
import { faEdit } from '@fortawesome/free-regular-svg-icons';
import { useAppDispatch, useAppSelector } from 'hooks/useRedux';
import { workflowActions } from 'state/workflow';
import FormWrapper from 'components/FormWrapper/FormWrapper';

type Props = {
  text: string;
};
const UnsignedConfirmation = ({ text }: Props) => {
  const { headerErrorText, buttonText } = useAppSelector((state) => state.confirmation.staticText);
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(workflowActions.setLoading(false));
  }, []);

  const onCompletePrescriptionClick = () => {
    dispatch(workflowActions.setLoading(true));
    dispatch(
      workflowActions.workflowNextStep({
        option: 'launch_rcopia',
      }),
    );
  };

  return (
    <div className="">
      <FormWrapper text={text}>
        <div className="row p-3 text-center">
          <div className="col-12">
            <div className="fa-6x">
              <FontAwesomeIcon
                icon={faEdit}
                className="text-warning"
              />
            </div>
            <h5>{headerErrorText}</h5>
            <div className="col-12 text-center">
              <button
                className="btn btn-primary m-1"
                onClick={onCompletePrescriptionClick}
              >
                <FontAwesomeIcon
                  icon={faFilePrescription}
                  color="white"
                />
                &nbsp;{buttonText}
              </button>
            </div>
          </div>
        </div>
      </FormWrapper>
    </div>
  );
};

export default UnsignedConfirmation;
