import { createAction } from '@reduxjs/toolkit';
import type { Workflow } from '../workflow/workflow.reducer';

interface LoadForms {
  workflow: Workflow;
  formId: string;
}

export const loadForms = createAction('loadForms', ({ workflow, formId }: LoadForms) => {
  const { forms } = workflow;

  const { sections } = Object.values(forms)[0];

  const data: Record<string, { value: any }> = {};

  sections.forEach((section) => {
    const { fields } = section;
    Object.assign(data, fields);
  });

  Object.keys(data).forEach((key) => {
    if (key.includes('dummy') || key.includes('rems:formType')) {
      delete data[key];
    }
  });

  Object.keys(data).forEach((key) => {
    const { value } = data[key];

    data[key] = value;
  });

  return {
    payload: { [formId]: data },
  };
});
