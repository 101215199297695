import { useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { faCheckCircle } from '@fortawesome/free-regular-svg-icons';
import { workflowActions, workflowSelectors } from 'state/workflow';

import { useAppDispatch, useAppSelector } from 'hooks/useRedux';
import FormWrapper from 'components/FormWrapper/FormWrapper';

type Props = {
  text: string;
};

const CompleteConfirmation = ({ text }: Props) => {
  const { headerSuccessText } = useAppSelector((state) => state.confirmation.staticText);
  const form = useAppSelector(workflowSelectors.workflowForm);
  const dispatch = useAppDispatch();

  const identifier = form?.identifier;

  useEffect(() => {
    dispatch(workflowActions.setLoading(false));
  }, [identifier]);

  return (
    <div className="">
      <FormWrapper text={text}>
        <div className="row p-3 text-center">
          <div className="col-12">
            <div className="fa-6x">
              <FontAwesomeIcon
                icon={faCheckCircle}
                className="text-success"
              />
            </div>
            <h5 className="text-success">{headerSuccessText}</h5>
          </div>
        </div>
      </FormWrapper>
    </div>
  );
};

export default CompleteConfirmation;
