export interface DefaultPropsInterface {
  identifier: string;
  title: { eng: string } | string;
  required: boolean;
  value: string;
  error?: boolean;
  choices: { eng: string[] };
  variant?: 'text' | 'number' | 'phone';
  units?: string;
}

export const reduxPropsDataDefault: DefaultPropsInterface = {
  identifier: '',
  title: { eng: '' } || '',
  choices: { eng: [] } || [],
  required: false,
  value: '',
  error: undefined,
  variant: undefined,
  units: '',
};
