import type { RootState } from '../store';

export const tokenSelector = (state: RootState) => state.auth.access_token;
export const isLoginSelector = (state: RootState) => state.auth.isLogin;
export const isLoadingSelector = (state: RootState) => state.auth.isLoading;
export const resetPasswordErrorSelector = (state: RootState) => state.auth.resetPasswordError;
export const forgotPasswordErrorSelector = (state: RootState) => state.auth.forgotPasswordError;
export const userSelector = (state: RootState) => state.auth.user;
export const wrongCredentialsSelector = (state: RootState) => state.auth.wrongCredentials;
export const newTabStatus = (state: RootState) => state.auth.newTabStatus;
