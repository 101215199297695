import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';

const SENTRY_DSN = process.env.REACT_APP_SENTRY_DSN;

function init() {
  if (SENTRY_DSN) {
    Sentry.init({
      dsn: SENTRY_DSN,
      integrations: [new BrowserTracing()],
      environment: process.env.REACT_APP_ENVIRONMENT,
      release: process.env.REACT_APP_VERSION,
      tracesSampleRate: 1.0,
      enabled: process.env.REACT_APP_ENVIRONMENT !== 'local',
    });
  }
}

export default {
  init,
};
