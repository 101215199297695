import { useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useAppSelector, useAppDispatch } from 'hooks/useRedux';
import { workflowActions, workflowSelectors } from 'state/workflow';
import {
  REMS_XYREM,
  REMS_XYWAV,
  RCOPIA_LOGIN,
  REMS_UNSIGNED_PRESCRIPTION,
  REMS_COMPLETED,
  REMS_RESEND_IDP_INVITE,
  REMS_REGISTRATION_INITIATED,
  REMS_REGISTRATION,
  REMS_COMPLETE_EPCS_REGISTRATION,
  REMS_REGISTRATION_LAC,
  REMS_RESEND_IDP_INVITE_SUCCESS,
  REMS_LAC_EMAIL_SUCCESS,
  REMS_LOCATION,
} from 'constants/identifierAndActions';
import {
  ERROR_EPCS_IDP_FAILED,
  ERROR_EPCS_INVITE_FAILED,
  ERROR_FORBIDDEN,
  ERROR_INVALID_EPCS,
} from 'constants/errorCodes';

const useIdentifierNavigation = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const location = useLocation();

  const form = useAppSelector(workflowSelectors.workflowForm);
  const { error } = useAppSelector(workflowSelectors.workflowSelector);

  const errorCode =
    (!Array.isArray(error) && typeof error === 'object' && error?.code) ||
    (!Array.isArray(error) && typeof error === 'object' && error?.status);

  const identifier = form?.identifier || errorCode;

  const handleNavigate = () => {
    switch (identifier) {
      case REMS_LOCATION:
        return { route: 'workflow/choose-location-and-medication', identifier };

      case REMS_XYREM:
      case REMS_XYWAV:
      case RCOPIA_LOGIN:
      case REMS_UNSIGNED_PRESCRIPTION:
      case REMS_COMPLETED:
        return { route: 'workflow/prescription', identifier };

      case REMS_RESEND_IDP_INVITE:
      case REMS_RESEND_IDP_INVITE_SUCCESS:
      case ERROR_EPCS_IDP_FAILED:
        return { route: 'workflow/identity-proofing', identifier };

      case ERROR_INVALID_EPCS:
        return { route: 'workflow/error', identifier };

      case ERROR_EPCS_INVITE_FAILED:
        return { route: 'workflow/initial-onboarding', identifier };

      case REMS_REGISTRATION:
        return {
          route: '/register',
          identifier,
        };

      case REMS_REGISTRATION_INITIATED:
        return {
          route: 'onboarding-initiated',
          identifier,
        };

      case REMS_COMPLETE_EPCS_REGISTRATION:
        return {
          route: 'workflow/epcs-registration',
          identifier,
        };

      case REMS_REGISTRATION_LAC:
      case REMS_LAC_EMAIL_SUCCESS:
        return { route: 'workflow/logical-access-control', identifier };

      case ERROR_FORBIDDEN:
        return { route: '/' };

      default:
        false;
    }
  };

  useEffect(() => {
    const navigateTo = handleNavigate();

    if (navigateTo) {
      const { route, identifier } = navigateTo;

      navigate(route, {
        state: {
          identifier: identifier,
        },
      });
    }
  }, [identifier]);

  useEffect(() => {
    dispatch(workflowActions.setLoading(false));
  }, [location, error]);
};

export default useIdentifierNavigation;
