import Loader from 'components/Loader/Loader';
import { useAppSelector } from 'hooks/useRedux';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { UserService } from 'services/Resources';
import { authActions, authSelectors } from 'state/auth';
import { useAppDispatch } from 'state/store';

const SSO = () => {
  const dispatch = useAppDispatch();
  const isLogin = useAppSelector(authSelectors.isLoginSelector);
  const navigate = useNavigate();

  const setUserData = (r: any) => {
    dispatch(authActions.setUser(r));
  };

  const getUserData = () => {
    UserService.get(
      {
        params: {
          enabled: undefined,
        },
      },
      setUserData,
      () => '',
    );
    navigate('/workflow');
    dispatch(authActions.setWrongCredentials(false));
  };

  useEffect(() => {
    if (isLogin) {
      getUserData();
    }
  }, [isLogin, getUserData]);

  useEffect(() => {
    const callback = (event: any) => {
      if (event.data.token) {
        sessionStorage.setItem('access_token', event.data.token);
        dispatch(authActions.loggingIn({}));
      }
    };

    window.addEventListener('message', callback);

    window.dispatchEvent(new Event('rems_sso_init'));

    return () => {
      window.removeEventListener('message', callback);
    };
  }, [dispatch]);

  return (
    <>
      <Loader />
    </>
  );
};

export default SSO;
