import { createSelector } from 'reselect';
import type { RootState } from '../store';

export const serviceType = (state: RootState) => state.serviceType.serviceType;

export const unreadServiceTypeSelector = createSelector(serviceType, (serviceType) => {
  const services = ['xyrem', 'xywav'];

  if (serviceType) {
    if (services.includes(serviceType)) {
      return serviceType;
    }
  }

  return false;
});
