import { combineReducers } from '@reduxjs/toolkit';
import auth from './auth/auth.reducer';
import cmeter from './cmeter/cmeter.reducer';
import forms from './forms/forms.reducer';
import language from './language/language.reducer';
import progressBar from './progressBar/progressBar.reducer';
import workflow from './workflow/workflow.reducer';
import serviceType from './serviceType/serviceType.reducer';
import confirmation from './confirmation/confirmation.reducer';
import hyperlink from './hyperlink/hyperlink.reducer';
export const rootReducer = combineReducers({
  auth,
  cmeter,
  forms,
  language,
  progressBar,
  workflow,
  serviceType,
  confirmation,
  hyperlink,
});

export type RootState = ReturnType<typeof rootReducer>;
