import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';

type Props = {
  icon: IconDefinition;
  children: React.ReactNode;
  title: string;
  header: string;
  titleClassName?: string;
};

const Card = ({
  icon,
  children,
  title,
  header,
  titleClassName = 'text-warning fw-bold h2',
}: Props) => {
  return (
    <div className="col h-full">
      <div className="container bg-white rounded-2 pt-2 pb-3 px-3 pt-md-3 pb-md-3 pt-md-3 px-md-4 h-100">
        <div className="d-flex justify-content-between pb-2 pb-md-3">
          <div className="d-flex align-items-end steps">
            <span>{header}</span>
          </div>
          <div>
            <FontAwesomeIcon
              icon={icon}
              className="text-muted text-warning icon"
              size="2x"
            />
          </div>
        </div>
        <div className="pb-2 pb-md-3">
          <h2 className={titleClassName}>{title}</h2>
        </div>
        <div className="pb-2">
          <span>{children}</span>
        </div>
      </div>
    </div>
  );
};

export default Card;
