import { formsActions } from 'state/forms';
import { ValuesType } from 'state/forms/forms.reducer';
import { AppDispatch } from 'state/store';
import { FieldFactoryInterface } from '../../models/models';

const onClear = (
  dispatch: AppDispatch,
  formId: number,
  methods: any,
  isSubmitted?: boolean,
  index?: number,
  registerForm?: boolean,
  formData?: FieldFactoryInterface,
) => {
  const form = formData || methods.getValues();
  const clearedTabsData: ValuesType = {};

  const setDefaultLocation = () => {
    clearedTabsData['provider:location:isDefault'] = 'Set Location As Default';
    dispatch(formsActions.setDefaultLocationKey('provider:location:isDefault'));
  };

  const resetFormValues = (key: string) => {
    clearedTabsData[key] = '';
    if (key.includes('provider:location:isDefault')) {
      clearedTabsData[key] = false;
    }
  };
  const onSubmit = () => {
    return true;
  };

  const resetForm = async () => {
    methods.reset(clearedTabsData);
    dispatch(formsActions.setFormValues({ formId, values: clearedTabsData }));
    if (isSubmitted) {
      methods.handleSubmit(onSubmit)();
    }
    return clearedTabsData;
  };

  Object.keys(form).map(async (key) => {
    if (
      (!index && key.includes('provider:location:')) ||
      (index && key.includes(`${index}:provider:location:`))
    ) {
      if (index !== index || !index) {
        resetFormValues(key);
      }
      if (index === index && form[key] && registerForm) {
        setDefaultLocation();
      }
    } else {
      clearedTabsData[key] = form[key];

      await methods.trigger(key);
    }
  });
  return resetForm();
};

export default onClear;
