import { useEffect } from 'react';
import { Outlet, useLocation, useNavigate, useOutlet } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'hooks/useRedux';
import { workflowActions, workflowSelectors } from 'state/workflow';
import Loader from 'components/Loader/Loader';
import { authActions } from 'state/auth';
import { formsActions } from 'state/forms';
import { progressBarActions } from 'state/progressBar';
import { TRY_AGAIN_OR_CONTACT_WITH_US } from 'constants/validationMessages';
import { ERROR_INVALID_REFRESH_TOKEN_REQUEST } from 'constants/errorCodes';

const InitWorkflow = () => {
  const dispatch = useAppDispatch();
  const outlet = useOutlet();
  const location = useLocation();
  const navigate = useNavigate();

  const error = useAppSelector(workflowSelectors.errorSelector);
  const errorCode = !Array.isArray(error) && typeof error === 'object' && error?.code;

  const reset = location.state?.reset;

  const showError = !reset && location.pathname === '/workflow';

  useEffect(() => {
    dispatch(workflowActions.initializeWorkflow('rems'));
  }, []);

  useEffect(() => {
    if (reset) {
      dispatch(formsActions.resetValues());
      dispatch(progressBarActions.resetStep());
      dispatch(workflowActions.initializeWorkflow('rems'));
    }
  }, [reset]);

  // Back handler for browser
  useEffect(() => {
    window.onpopstate = () => {
      navigate('/workflow', {
        state: {
          reset: true,
        },
        replace: true,
      });
    };

    return () => {
      window.onpopstate = () => {
        dispatch(authActions.logout());
        dispatch(workflowActions.reset());
        navigate('/', { replace: true });
      };
    };
  }, [window]);

  useEffect(() => {
    if (errorCode === ERROR_INVALID_REFRESH_TOKEN_REQUEST) {
      dispatch(formsActions.resetValues());
      dispatch(progressBarActions.resetStep());
      navigate('/', { replace: true });
    }

    if (error && showError) {
      navigate('/workflow/error', {
        state: {
          disableButton: true,
          errorSubTitle: TRY_AGAIN_OR_CONTACT_WITH_US,
        },
      });
      dispatch(workflowActions.setLoading(false));
    }
  }, [error]);

  if (outlet) {
    return <Outlet />;
  }

  return (
    <div className="workflow-container app">
      <Loader />
    </div>
  );
};

export default InitWorkflow;
