import { useEffect } from 'react';
import { RouterProvider } from 'react-router-dom';
import * as Sentry from '@sentry/react';
import { useAppSelector, useAppDispatch } from './hooks/useRedux';
import { authActions, authSelectors } from './state/auth';
import { router } from 'rootNavigation';
import { hyperlinkActions } from 'state/hyperlink';
import Loader from './components/Loader/Loader';
import './style/App.scss';

function App() {
  const dispatch = useAppDispatch();

  const isLoading = useAppSelector(authSelectors.isLoadingSelector);

  useEffect(() => {
    dispatch(authActions.loggingIn({}));
    dispatch(hyperlinkActions.getHyperlinks({}));
  }, []);

  useEffect(() => {
    const changeStatus = () => {
      dispatch(authActions.setInternetStatus(navigator.onLine));
    };

    window.addEventListener('online', changeStatus);
    window.addEventListener('offline', changeStatus);

    return () => {
      window.removeEventListener('online', changeStatus);
      window.removeEventListener('offline', changeStatus);
    };
  }, []);

  if (isLoading) {
    return <Loader />;
  }

  return <RouterProvider router={router} />;
}

export default Sentry.withProfiler(App);
