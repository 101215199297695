import { UseFormRegister, UseFormSetValue } from 'react-hook-form';
import { useAppDispatch, useAppSelector } from '../../hooks/useRedux';
import { formsActions, formsSelectors } from '../../state/forms';

import type { ValuesType } from '../../state/forms/forms.reducer';

interface CheckboxProps {
  register: UseFormRegister<ValuesType>;
  formKey: string;
  required?: boolean;
  title: string;
  isDisabled?: boolean;
  setValue?: UseFormSetValue<ValuesType>;
}

const Checkbox = ({
  required = false,
  formKey,
  register,
  title,
  setValue,
  isDisabled,
}: CheckboxProps) => {
  const dispatch = useAppDispatch();

  const defaultLocationKey = useAppSelector(formsSelectors.defaultLocationKeySelector);

  const defaultLocation = () => {
    if (formKey.includes('provider:location'))
      if (setValue && defaultLocationKey !== formKey) {
        setValue(defaultLocationKey, false);
        dispatch(formsActions.setDefaultLocationKey(formKey));
      } else {
        dispatch(formsActions.setDefaultLocationKey(''));
      }
  };

  return (
    <>
      <div className={`form-check form-check-inline `}>
        <input
          className="form-check-input"
          type="checkbox"
          id={formKey}
          disabled={isDisabled}
          {...register(formKey, {
            required,
            onChange: defaultLocation,
          })}
        />
        <label
          className="form-check-label"
          htmlFor={formKey}
        >
          {title}
        </label>
        {required && <span className="text-danger">*</span>}
      </div>
    </>
  );
};
export default Checkbox;
