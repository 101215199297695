import { createSlice } from '@reduxjs/toolkit';

interface ProgressBarState {
  step: number;
}

export const initialState: ProgressBarState = {
  step: 0,
};

const progressBarSlice = createSlice({
  name: 'progressBar',
  initialState,
  reducers: {
    nextStep(state) {
      if (state.step < 4) {
        state.step = state.step + 1;
      }
    },
    prevStep(state) {
      if (state.step > 0) {
        state.step = state.step - 1;
      }
    },
    resetStep(state) {
      state.step = 0;
    },
  },
});

export const progressBarReducerActions = progressBarSlice.actions;
export default progressBarSlice.reducer;
