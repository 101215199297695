import { faCircleXmark } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

interface Props {
  onClick: () => void;
  name: string;
}
const ItemWithDeleteOption = ({ onClick, name }: Props) => {
  return (
    <div className="small">
      <span
        className="text-black"
        data-testid="item-name"
      >
        {name}
      </span>
      <button
        className="btn btn-link btn-sm p-1"
        type="button"
        data-testid="delete-item-button"
        onClick={onClick}
      >
        <FontAwesomeIcon
          icon={faCircleXmark}
          className="text-danger shadow-none"
        />
      </button>
    </div>
  );
};

export default ItemWithDeleteOption;
