import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { NewTabStatus } from './auth.types';

interface User {
  email: string;
  npi: string;
  name: string;
  first_name: string;
  last_name: string;
  rc_practice_username: string;
  rc_username: string;
}

interface AuthState {
  isLogin: boolean;
  isLoading: boolean;
  access_token: string;
  wrongCredentials: boolean;
  resetPasswordError: string | string[];
  forgotPasswordError: string;
  providerInformation: any;
  user: User | false;
  internetConnection: boolean;
  newTabStatus: NewTabStatus;
}
interface AuthLoginPayload {
  access_token: string;
}

export const initialState: AuthState = {
  isLogin: false,
  isLoading: true,
  wrongCredentials: false,
  access_token: '',
  providerInformation: false,
  resetPasswordError: '',
  forgotPasswordError: '',
  user: false,
  internetConnection: true,
  newTabStatus: null,
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    login(state, action: PayloadAction<AuthLoginPayload>) {
      const { access_token } = action.payload;
      state.isLogin = true;
      state.isLoading = false;
      state.access_token = access_token;
    },
    logout(state) {
      state.isLogin = false;
      state.isLoading = false;
      state.access_token = '';
    },
    loggingFailed(state) {
      state.isLogin = false;
      state.isLoading = false;
      state.access_token = '';
    },
    setLoading(state, action) {
      state.isLoading = action.payload;
    },
    register(state, action: PayloadAction<AuthLoginPayload>) {
      const { access_token } = action.payload;
      state.isLoading = false;
      state.access_token = access_token;
    },
    setWrongCredentials(state, action) {
      state.wrongCredentials = action.payload;
    },
    setResetPasswordError(state, action: { payload: string }) {
      state.resetPasswordError = action.payload;
    },
    setForgotPasswordError(state, action: { payload: string }) {
      state.forgotPasswordError = action.payload;
    },
    setUser(state, action) {
      state.user = action.payload;
    },
    setInternetStatus(state, action) {
      state.internetConnection = action.payload;
    },
    setNewTabStatus(state, action: { payload: NewTabStatus }) {
      state.newTabStatus = action.payload;
    },
  },
});

export const reducerAuthActions = authSlice.actions;
export default authSlice.reducer;
