import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTriangleExclamation } from '@fortawesome/free-solid-svg-icons';

const OneTabErrorComponent = () => {
  return (
    <>
      <div className="navbar bg-info text-white">
        <div className="container-fluid justify-content-md-between justify-content-center">
          <div className="col-md-4 col-12 text-center text-md-left justify-content-md-center">
            <div className="float-md-start">
              <img
                src={require('./../../assets/logo/jazz_logo_white.png')}
                className="align-text-middle d-inline-block"
              />
            </div>
          </div>
        </div>
      </div>
      <div className="container mt-5">
        <div className="col-12">
          <div className="card mb-2">
            <div className={`card-header bg-secondary text-white fw-bolder`}>Error</div>

            <div className="card-body p-0">
              <div className="row px-3 py-1">
                <div className="row g-0 text-center">
                  <div className="col-12 p-3">
                    <h5 className="fw-bold">
                      Another session is currently active. Please complete that session or close the
                      window to continue.
                    </h5>
                    <div className="fa-6x">
                      <i className="fa-solid fa-triangle-exclamation text-danger"></i>
                      <FontAwesomeIcon
                        icon={faTriangleExclamation}
                        className="text-danger"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default OneTabErrorComponent;
