import { useAppSelector } from 'hooks/useRedux';
import { workflowSelectors } from 'state/workflow';

const ProviderInformation = () => {
  const form = useAppSelector(workflowSelectors.remsFormValuesSelector);
  const provider = form?.values;

  return (
    <>
      {provider && (
        <div className="row g-0 bg-light p-3 small">
          <div className="col-4">
            <div className="text-warning fw-bold border-bottom">Prescriber Information</div>
            <div>{provider['dummy:prescriber:information:name']}</div>
            <div>{provider['dummy:prescriber:information:location']}</div>
            <div>{provider['dummy:prescriber:information:address']}</div>
            <div>{provider['dummy:prescriber:information:cityStateZip']}</div>
          </div>
          <div className="col-4">
            <div className="text-warning fw-bold border-bottom">Contact Information</div>
            <div>Phone: {provider['dummy:prescriber:contact:phone']}</div>
            <div>Fax: {provider['dummy:prescriber:contact:fax']}</div>
            <div>Office Contact: {provider['dummy:prescriber:contact:officeContact']}</div>
            <div>Office Phone: {provider['dummy:prescriber:contact:officePhone']}</div>
          </div>
          <div className="col-4">
            <div className="text-warning fw-bold border-bottom">License Information</div>
            <div>NPI: {provider['dummy:prescriber:license:npi']}</div>
            <div>DEA: {provider['dummy:prescriber:license:dea']}</div>
            <div>State License: {provider['dummy:prescriber:license:stateLicense']}</div>
          </div>
        </div>
      )}
    </>
  );
};

export default ProviderInformation;
