import DosingRow from 'components/DosingRow/DosingRow';

const TitratedDosing = () => {
  return (
    <div
      className="tab-pane"
      id="pills-titrated"
      role="tabpanel"
      aria-labelledby="pills-titrated-tab"
      tabIndex={0}
    >
      <h5 className="py-2 text-info text-center">Titrated XYREM Dosing: Titrate to Effect</h5>

      <DosingRow
        title="Starting Dose"
        withOperators
        fields={{
          doses: [
            'rems:titratedDosing:effect:startingDose:dose:1',
            'rems:titratedDosing:effect:startingDose:dose:2',
          ],
          totalDose: 'rems:titratedDosing:effect:startingDose:dose:total',
          timeOfDrugTaking: 'rems:titratedDosing:effect:startingDose:dose:numOfDays',
        }}
      />
      <DosingRow
        title="1st Titration"
        withOperators
        fields={{
          doses: [
            'rems:titratedDosing:effect:1stTitration:dose:1',
            'rems:titratedDosing:effect:1stTitration:dose:2',
          ],
          totalDose: 'rems:titratedDosing:effect:1stTitration:dose:total',
          timeOfDrugTaking: 'rems:titratedDosing:effect:1stTitration:dose:numOfDays',
        }}
      />
      <DosingRow
        title="2nd Titration"
        withOperators
        fields={{
          doses: [
            'rems:titratedDosing:effect:2ndTitration:dose:1',
            'rems:titratedDosing:effect:2ndTitration:dose:2',
          ],
          totalDose: 'rems:titratedDosing:effect:2ndTitration:dose:total',
          timeOfDrugTaking: 'rems:titratedDosing:effect:2ndTitration:dose:numOfDays',
        }}
      />
      <DosingRow
        title="3rd Titration"
        withOperators
        fields={{
          doses: [
            'rems:titratedDosing:effect:3rdTitration:dose:1',
            'rems:titratedDosing:effect:3rdTitration:dose:2',
          ],
          totalDose: 'rems:titratedDosing:effect:3rdTitration:dose:total',
          timeOfDrugTaking: 'rems:titratedDosing:effect:3rdTitration:dose:numOfDays',
        }}
      />
      <div className="col-12 small px-2 pt-3 fw-bold">
        <div>
          First dose is ordinarily taken at bedtime second dose is taken 2.5 to 4 hours later.
        </div>
        <div>
          *For pediatric patients who sleep more than 8 hours per night, the first dose of XYREM may
          be given at bedtime or after an initial period of sleep.
        </div>
        <div>
          **If XYREM is used in patients 7 years of age and older who weigh less than 20 kg, a lower
          starting dosage, lower maximum weekly dosage increases, and lower total maximum nightly
          dosage should be considered. Some patients may achieve better responses with unequal doses
          at bedtime and 2.5 to 4 hours later.
        </div>
      </div>
    </div>
  );
};

export default TitratedDosing;
