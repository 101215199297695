import DosingRow from 'components/DosingRow/DosingRow';

const FixedDosing = () => {
  return (
    <div
      className="tab-pane"
      id="pills-fixed-2"
      role="tabpanel"
      aria-labelledby="pills-fixed-tab-2"
    >
      <h5 className="pt-3 text-info text-center">Fixed XYWAV Dosing</h5>
      <h6 className="fw-bold">1 time a night dosing (IH patients)</h6>
      <DosingRow
        // fields={['rems:fixedDosing:ih:dose']}
        fields={{
          doses: ['rems:fixedDosing:ih:dose'],
        }}
        wideField={0}
      />
    </div>
  );
};

export default FixedDosing;
