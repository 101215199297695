import { useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import dayjs from 'dayjs';
import { useAppDispatch, useAppSelector } from 'hooks/useRedux';
import moveStep from 'hooks/moveStep';
import PrescriptionFormXywav from './PrescriptionFormXywav/PrescriptionFormXywav';
import PrescriptionFormXyrem from './PrescriptionFormXyrem/PrescriptionFormXyrem';
import { serviceTypeSelectors } from 'state/serviceType';
import { ValuesType } from 'state/forms/forms.reducer';
import { workflowActions } from 'state/workflow';

export interface PrescriptionFormState {
  substitutions: string;
  signature: string;
  signingDate: string;
}

const PrescriptionForm = () => {
  const dispatch = useAppDispatch();

  const serviceType = useAppSelector(serviceTypeSelectors.unreadServiceTypeSelector);
  const formId = useAppSelector((store) => store.workflow.workflow?.form.uuid);
  const formValues = useAppSelector((store) => store.forms.values[formId]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const today = dayjs(new Date()).format('MM/DD/YYYY');

  const { onNextClick, onPrevClick } = moveStep();

  const methods = useForm({
    defaultValues: formValues,
  });

  const onSubmit = (data: ValuesType, e: any) => {
    const submitter = e?.nativeEvent.submitter;
    const buttonName = submitter[Object.keys(submitter)[1]].name;

    if (buttonName === 'warning') {
      onPrevClick(formId, data);
    } else {
      dispatch(workflowActions.setLoading(true));
      onNextClick(formId, data);
    }
  };

  const onError = (errors: any, e: any) => {
    const submitter = e?.nativeEvent.submitter;
    const buttonName = submitter[Object.keys(submitter)[1]].name;
    const data = methods.getValues();

    if (buttonName === 'warning') {
      onPrevClick(formId, data);
    }
  };

  return (
    <FormProvider {...methods}>
      {serviceType === 'xyrem' ? (
        <form onSubmit={methods.handleSubmit(onSubmit, onError)}>
          <PrescriptionFormXyrem today={today} />
        </form>
      ) : (
        <form onSubmit={methods.handleSubmit(onSubmit, onError)}>
          <PrescriptionFormXywav today={today} />
        </form>
      )}
    </FormProvider>
  );
};

export default PrescriptionForm;
