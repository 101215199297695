import DosingRow from 'components/DosingRow/DosingRow';

const FixedDosing = () => {
  return (
    <div
      className="tab-pane"
      id="pills-fixed"
      role="tabpanel"
      aria-labelledby="pills-fixed-tab"
      tabIndex={0}
    >
      <h5 className="py-2 text-info text-center">Fixed XYREM Dosing</h5>
      <DosingRow
        fields={{
          doses: ['rems:fixedDosing:dose:1', 'rems:fixedDosing:dose:2'],
          totalDose: 'rems:fixedDosing:dose:total',
        }}
        wideField={1}
        withOperators
      />
    </div>
  );
};

export default FixedDosing;
