import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import FormWrapper from 'components/FormWrapper/FormWrapper';
import { ERROR_LAC_LINK_BLOCKED } from 'constants/errorCodes';
import { VALUE_TOO_LONG } from 'constants/validationMessages';
import FormField from 'containers/FormField/FormField';
import moveStep from 'hooks/moveStep';
import { useQuery } from 'hooks/useQuery';
import { useAppDispatch, useAppSelector } from 'hooks/useRedux';
import { useEffect } from 'react';
import { Alert } from 'react-bootstrap';
import { FormProvider, useForm } from 'react-hook-form';
import { formsActions } from 'state/forms';
import { workflowActions, workflowSelectors } from 'state/workflow';

const LACConfirmation = () => {
  const query = useQuery();
  const { labelText } = useAppSelector((state) => state.confirmation.staticText);
  const name = useAppSelector((state) => state.confirmation.firstName);
  const surname = useAppSelector((state) => state.confirmation.lastName);
  const isLoading = useAppSelector((state) => state.workflow.isLoading);
  const workflow = useAppSelector((store) => store.workflow.workflow);
  const error = useAppSelector(workflowSelectors.errorSelector);
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(workflowActions.setLoading(false));
  }, []);

  const setErrorMessage = () => {
    if (!error) {
      return null;
    }
    if (typeof error === 'string') {
      return error;
    }

    if (Array.isArray(error)) {
      return error[0];
    }
    if (typeof error != 'string' && error.message) {
      return error.message;
    }
  };

  const methods = useForm();

  const { onNextClick } = moveStep();

  const submitTokenReceivedFromEmail = methods.handleSubmit(async (data) => {
    dispatch(workflowActions.setLoading(true));
    const formId = workflow?.form.uuid || '';
    if (error) {
      dispatch(formsActions.setFormValues({ formId, values: data }));
      dispatch(workflowActions.workflowRetryLatestActionStep());
    }

    onNextClick(formId, { ...data, authorizationLinkToken: query.get('token') });

    methods.reset();
  });

  return (
    <FormProvider {...methods}>
      <div className="container ">
        <form onSubmit={submitTokenReceivedFromEmail}>
          <div className="row g-o">
            <FormWrapper
              disableReturn
              disableError={
                !!error &&
                typeof error != 'string' &&
                !Array.isArray(error) &&
                error.code != ERROR_LAC_LINK_BLOCKED
              }
              type="provider"
              text={labelText}
            >
              <div className="row p-3 justify-center">
                <div className="col-4"></div>
                <div className="col-12 col-md-4">
                  <h2 className="text-center">
                    Hi {name} {surname}
                  </h2>

                  <p className="text-center">Enter the token received in the email</p>

                  {error && !isLoading && (
                    <div className="d-flex justify-content-center">
                      <Alert variant={'danger'}>{setErrorMessage()}</Alert>
                    </div>
                  )}
                  <FormField
                    validation={{
                      maxLength: {
                        value: 25,
                        message: VALUE_TOO_LONG,
                      },
                    }}
                    type="text"
                    identifier="authorizationUserToken"
                  />
                  <div className="text-center">
                    <button
                      className="btn btn-primary btn-block mt-3"
                      disabled={isLoading}
                    >
                      Submit
                      {isLoading && (
                        <FontAwesomeIcon
                          icon={faSpinner}
                          color="white"
                          className="mx-1"
                          spinPulse={true}
                        />
                      )}
                    </button>
                  </div>
                </div>
                <div className="col-4"></div>
              </div>
            </FormWrapper>
          </div>
        </form>
      </div>
    </FormProvider>
  );
};

export default LACConfirmation;
