import Loader from 'components/Loader/Loader';
import isDuplicatedWindow from 'helpers/checkIfTabIsDuplicated';
import React, { useEffect, useState } from 'react';
import { ActiveRefreshTokenCheckService } from 'services/Resources';

const DefaultOneTabViolationComponent = () => {
  return <div>Sorry! You can only have this application opened in one tab</div>;
};

type Props = {
  children: React.ReactNode;
  OneTabViolationComponent?: React.FC;
  localStorageTimeout?: number;
  localStorageResetInterval?: number;
  appName?: string;
};
export const OneTabEnforcer = ({
  OneTabViolationComponent = DefaultOneTabViolationComponent,
  localStorageTimeout = 15 * 1000, // 15,000 milliseconds = 15 seconds.
  localStorageResetInterval = 10 * 1000, // 10,000 milliseconds = 10 seconds.
  appName = 'default-app-name', // has to be unique!
  children,
}: Props) => {
  const [tokenValid, setTokenValid] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const checkIfRefreshTokenIsValid = async () => {
      setLoading(true);
      await ActiveRefreshTokenCheckService.get(
        {
          params: {
            enabled: undefined,
          },
          withCredentials: true,
        },
        (res: { active: boolean }) => {
          setTokenValid(res.active);
        },
        () => {
          setTokenValid(false);
        },
      );

      setLoading(false);
    };

    checkIfRefreshTokenIsValid();
  }, []);

  if (loading) {
    return <Loader />;
  }

  if (tokenValid && isDuplicatedWindow(localStorageTimeout, localStorageResetInterval, appName)) {
    return <OneTabViolationComponent />;
  } else {
    return <>{children}</>;
  }
};
