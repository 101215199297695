import { useState, useRef, useLayoutEffect } from 'react';
import { useAppDispatch, useAppSelector } from 'hooks/useRedux';
import FormService from 'services/Form';
import { workflowActions, workflowSelectors } from 'state/workflow';
import FormWrapper from 'components/FormWrapper/FormWrapper';
import LoadingContent from 'components/LoadingContent/LoadingContent';

interface RcopiaLoginFormProps {
  form: FormService;
  text: string;
}

function RcopiaLoginForm({ form, text }: RcopiaLoginFormProps) {
  const dispatch = useAppDispatch();

  const formRef = useRef(null);

  const { error } = useAppSelector(workflowSelectors.workflowSelector);

  const [isLoading, setIsLoading] = useState(true);

  const rcopiaLandingUrl = form.values.rcopiaLandingUrl as string;
  const token = form.values.rcopiaToken as string;

  useLayoutEffect(() => {
    if (formRef?.current) {
      (formRef.current as HTMLFormElement).submit();
    }
  }, [formRef]);

  const nextStep = () => {
    dispatch(workflowActions.setLoading(true));
    dispatch(workflowActions.workflowNextStep());
  };

  return (
    <>
      {!error ? (
        <>
          <FormWrapper text={text}>
            <LoadingContent />
          </FormWrapper>
          <div className="fill-window">
            {!isLoading && (
              <a
                id="closebutton"
                className="btn btn-secondary container-fluid rounded-0"
                onClick={nextStep}
              >
                Close Rcopia and Continue
              </a>
            )}
            <form
              ref={formRef}
              target="target_iframe"
              method="post"
              action={rcopiaLandingUrl}
            >
              <input
                hidden
                name="token"
                defaultValue={token}
              />
            </form>
            <iframe
              onLoad={() => {
                setIsLoading(false);
              }}
              name="target_iframe"
              style={{ width: '100vw', height: '95%' }}
            ></iframe>
          </div>
        </>
      ) : (
        <FormWrapper text="Complete Prescription" />
      )}
    </>
  );
}

export default RcopiaLoginForm;
