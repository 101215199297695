import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';

interface ModalProps {
  show: boolean;
  handleClose: () => void;
  handleSubmit?: () => void;
  cancelButtonText?: string;
  continueButtonText?: string;
  text?: string;
  classNameText?: string;
}

const DefaultModal = ({
  handleClose,
  handleSubmit,
  show,
  classNameText,
  cancelButtonText = 'Cancel',
  continueButtonText = 'Continue',
  text = '',
}: ModalProps) => {
  return (
    <Modal
      show={show}
      onHide={handleClose}
    >
      <Modal.Header />
      <Modal.Body className={classNameText}>{text}</Modal.Body>
      <Modal.Footer className="d-flex justify-content-center">
        <Button
          variant="outline-secondary"
          onClick={handleClose}
        >
          {cancelButtonText}
        </Button>
        {handleSubmit && (
          <Button
            variant="primary"
            onClick={handleSubmit}
          >
            {continueButtonText}
          </Button>
        )}
      </Modal.Footer>
    </Modal>
  );
};

export default DefaultModal;
