const XyremInfo = () => {
  return (
    <>
      <div className="bg-light w-auto p-3 small">
        <span className="me-2 fw-bold fw-uppercase">Dispensing Instructions</span>
        <div>
          <span className="fw-bold">Directions:</span> Take first dose p.o., diluted in ¼ cup of
          water, at bedtime. Take second dose p.o., diluted in ¼ cup of water 2.5 to 4 hours later.
        </div>
        <div>
          <span className="fw-bold">Note:</span> Prepare both doses at the same time prior to
          bedtime. The XYREM shipment does not include water for dilution.
        </div>
      </div>
      <div className="row px-3 py-1">
        <div className="col-12">
          <ul className="circle-list">
            <li>
              Initial prescription fill cannot exceed 1 month of therapy. Refills cannot exceed 3
              months supply.
            </li>
            <li className="fw-bold">
              Please complete <u>EITHER</u> the titrated dosing <u>OR</u> fixed dosing section.
            </li>
            <li className="fw-bold">
              Please see the Prescriber Brochure and the Prescribing Information for additional
              dosing instructions.
            </li>
          </ul>
        </div>
      </div>
    </>
  );
};

export default XyremInfo;
