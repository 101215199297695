import FormWrapper from 'components/FormWrapper/FormWrapper';
import NameHeader from 'components/nameHeader/NameHeader';

const NotFoundPage = () => {
  return (
    <>
      <NameHeader
        disableLogoOnClick
        serviceType={false}
        remsFlow={true}
      />
      <div className="container mt-5">
        <FormWrapper
          text="Page Not Found"
          disableReturn
          customError={"We're sorry, but the page you were trying to access does not exist."}
          type="provider"
        />
      </div>
    </>
  );
};

export default NotFoundPage;
