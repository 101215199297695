import { useEffect } from 'react';
import { Outlet, useLocation, useOutlet } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'hooks/useRedux';
import Loader from 'components/Loader/Loader';
import { ConfirmationActions } from 'state/confirmation';
import { useQuery } from 'hooks/useQuery';
import NameHeader from 'components/nameHeader/NameHeader';
import FormWrapper from 'components/FormWrapper/FormWrapper';

const InitConfirmationWorkflow = () => {
  const dispatch = useAppDispatch();
  const outlet = useOutlet();
  const query = useQuery();
  const error = useAppSelector((state) => state.confirmation.error);
  const { expiredTokenErrorText, labelText } = useAppSelector(
    (state) => state.confirmation.staticText,
  );
  const location = useLocation();

  useEffect(() => {
    dispatch(ConfirmationActions.setName(location.pathname.split('/')[1]));

    switch (location.pathname.split('/')[1]) {
      case 'lac_authorization':
        dispatch(
          ConfirmationActions.setStaticText({
            labelText: 'Logical Access Control (LAC)',
            headerErrorText:
              'The provider’s EPCS grant was not updated using Logical Access Control (LAC)',
            headerSuccessText:
              'Provider’s EPCS grant successfully updated using Logical Access Control (LAC)',
            buttonText: 'Complete LAC',
            expiredTokenErrorText:
              'This link has expired. Reach out to the provider to trigger a new request',
          }),
        );
        break;
      case 'tou':
        dispatch(
          ConfirmationActions.setStaticText({
            labelText: 'Terms of Use',
            headerErrorText: '',
            headerSuccessText: 'Completed',
            buttonText: 'Complete',
            expiredTokenErrorText: 'This link has expired.',
          }),
        );
        break;
      default:
        dispatch(
          ConfirmationActions.setStaticText({
            labelText: '',
            headerErrorText: '',
            headerSuccessText: '',
            buttonText: '',
            expiredTokenErrorText: '',
          }),
        );
    }

    const getWorkflow = async () => {
      await dispatch(ConfirmationActions.ConfirmationSubmitEmailToken({ key: query.get('token') }));
    };

    getWorkflow();
  }, []);

  if (error) {
    return (
      <>
        <NameHeader
          disableLogoOnClick
          serviceType={false}
          remsFlow={true}
        />
        <div className="container mt-5">
          <FormWrapper
            disableReturn
            customError={expiredTokenErrorText}
            type="provider"
            text={labelText}
          />
        </div>
      </>
    );
  }

  if (outlet) {
    return <Outlet />;
  }

  return (
    <div className="workflow-container app">
      <Loader />
    </div>
  );
};

export default InitConfirmationWorkflow;
