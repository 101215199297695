/* eslint-disable @typescript-eslint/ban-ts-comment */
// @ts-nocheck
import Service from './Api';

class Location extends Service {
  constructor() {
    super();
    this.url = '/api/location/:id/';
    this.urlParams = {
      id: '@id',
    };
  }
  localize = (lat, lng, success, error) => {
    return this.get({ params: { lat, lng, id: 'localize' } }, success, error);
  };
}

class LocationUser extends Service {
  constructor() {
    super();
    this.url = '/api/location_user/:id/';
    this.urlParams = {
      id: '@id',
    };
  }
}

class WorkflowTemplate extends Service {
  constructor() {
    super();
    this.url = '/api/workflow_template/:id/:action/';
    this.urlParams = {
      id: '@id',
      action: '@action',
    };
  }
}

class WorkflowTemplateStep extends Service {
  constructor() {
    super();
    this.url = '/api/workflow_template_step/:id/:action/';
    this.urlParams = {
      id: '@id',
      action: '@action',
    };
  }
}

class Workflow extends Service {
  constructor() {
    super();
    this.url = '/api/workflow/:id/:action/';
    this.urlParams = {
      id: '@id',
      action: '@action',
    };
  }
}

class WorkflowStep extends Service {
  constructor() {
    super();
    this.url = '/api/workflow_step/:id/:action/:actionId/';
    this.urlParams = {
      id: '@id',
      action: '@action',
      actionId: '@actionId',
    };
  }
}

class WorkflowForm extends Service {
  constructor() {
    super();
    this.url = '/api/workflow_form/:id/:action/';
    this.urlParams = {
      id: '@id',
      action: '@action',
    };
  }
}

class ScheduleGroup extends Service {
  constructor() {
    super();
    this.url = '/api/schedule/group/:id/:action/';
    this.urlParams = {
      id: '@id',
      action: '@action',
    };
  }
}

class Login extends Service {
  constructor() {
    super();
    this.url = '/oauth/token/';
    this.service.interceptors.response.use(Service.handleSuccess, this.handleError);
  }
  handleError = (error) => {
    return Promise.reject(error);
  };
}

class Register extends Service {
  constructor() {
    super();
    this.url = '/api/registration_workflow/';
    this.service.interceptors.response.use(Service.handleSuccess, this.handleError);
  }
  handleError = (error) => {
    return Promise.reject(error);
  };
}

class Logout extends Service {
  constructor() {
    super();
    this.url = '/oauth/revoke_token/';
    this.service.interceptors.response.use(Service.handleSuccess, this.handleError);
  }
  handleError = (error) => {
    return Promise.reject(error);
  };
}

class ForgotPassword extends Service {
  constructor() {
    super();
    this.url = '/api/reset_password/';
    this.service.interceptors.response.use(Service.handleSuccess, this.handleError);
  }
  handleError = (error) => {
    return Promise.reject(error);
  };
}

class ResetPassword extends Service {
  constructor() {
    super();
    this.url = '/api/reset_password/confirm/';
    this.service.interceptors.response.use(Service.handleSuccess, this.handleError);
  }
  handleError = (error) => {
    return Promise.reject(error);
  };
}
class User extends Service {
  constructor() {
    super();
    this.url = '/api/user';
  }
}

class WorkflowSurveyToken extends Service {
  constructor() {
    super();
    this.url = '/api/token/:key';
    this.urlParams = {
      key: '@key',
    };
    this.service.interceptors.response.use(Service.handleSuccess, this.handleError);
  }
  handleError = () => {
    return Promise.reject(error);
  };
}

class Confirmation extends Service {
  constructor() {
    super();
    this.url = '/api/token';
    this.urlParams = {
      key: '@key',
    };
  }
}

class ActiveRefreshTokenCheck extends Service {
  constructor() {
    super();
    this.url = '/api/is_refresh_token_active/';
    this.urlParams = {
      key: '@key',
    };
  }
}

class ConfigurableHyperLink extends Service {
  constructor() {
    super();
    this.url = '/api/storage';
    this.urlParams = {
      key: '@key',
    };
  }
}

export const WorkflowSurveyTokenService = new WorkflowSurveyToken();
export const LocationService = new Location();
export const LocationUserService = new LocationUser();
export const WorkflowTemplateService = new WorkflowTemplate();
export const WorkflowTemplateStepService = new WorkflowTemplateStep();
export const WorkflowService = new Workflow();
export const WorkflowStepService = new WorkflowStep();
export const WorkflowFormService = new WorkflowForm();
export const ScheduleGroupService = new ScheduleGroup();
export const LoginService = new Login();
export const LogoutService = new Logout();
export const ForgotPasswordService = new ForgotPassword();
export const ResetPasswordService = new ResetPassword();
export const UserService = new User();
export const ActiveRefreshTokenCheckService = new ActiveRefreshTokenCheck();
export const ConfirmationService = new Confirmation();
export const RegisterService = new Register();
export const ConfigurableHyperLinkService = new ConfigurableHyperLink();
