import type { RootState } from '../store';
import { createSelector } from 'reselect';

export const valuesSelector = (state: RootState) => state.forms.values;
export const formUuidSelector = (state: RootState) => state.workflow.workflow?.form?.uuid;
export const defaultLocationKeySelector = (state: RootState) => state.forms.defaultLocationKey;
export const tabValue = (state: RootState) => state.forms.tabValue;
export const fullSignature = (state: RootState) => state.forms.fullSignature;

export const formSelector = createSelector(formUuidSelector, valuesSelector, (uuid, values) => {
  return values[uuid];
});

export const stateSelector = createSelector(formSelector, (form) => {
  if (form && form['patient:state']) {
    return form['patient:state'];
  }

  return '';
});

export const formValueSelector = createSelector(formSelector, (form) => {
  const locations: string[] = [];

  if (form) {
    for (const [key, value] of Object.entries(form)) {
      if (key.includes('provider:location:name')) {
        if (value) {
          locations.push(value);
        }
      }
    }

    if (locations.length) {
      return locations;
    }

    return false;
  }
  return false;
});
