import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const LoadingContent = () => {
  return (
    <div className="row p-3 text-center">
      <div className="col-12">
        <div className="col-12">
          <a className="link-dark link-decoration-none text-decoration-none fa-5x">
            <FontAwesomeIcon
              icon={faSpinner}
              color="black"
              spinPulse={true}
            />
            <h5>Synchronizing Data - Please Wait</h5>
          </a>
        </div>
      </div>
    </div>
  );
};

export default LoadingContent;
