import { useLocation, useNavigate } from 'react-router-dom';
import { faCircleCheck } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useAppDispatch, useAppSelector } from 'hooks/useRedux';
import { authActions, authSelectors } from 'state/auth';
import { useEffect } from 'react';
import { workflowActions } from 'state/workflow';

const SuccessComponent = ({ title }: { title: string }) => {
  useEffect(() => {
    dispatch(workflowActions.setLoading(false));
  }, []);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const identifier = location.state?.identifier;

  const token = useAppSelector(authSelectors.tokenSelector);

  const handleClick = () => {
    dispatch(authActions.logOutAndRemoveRefreshToken({ token: token }));
    navigate('/');
  };

  if (!identifier) {
    return null;
  }

  return (
    <>
      <div className="row p-3 text-center">
        <div className="col-12">
          <div className="fa-6x">
            <FontAwesomeIcon
              icon={faCircleCheck}
              className="text-success"
            />
          </div>
          <h5 className="text-success">{title}</h5>

          <a
            href="#"
            className="btn btn-primary my-2"
            onClick={handleClick}
          >
            Return to Login
          </a>
        </div>
      </div>
    </>
  );
};

export default SuccessComponent;
