import DosingInfo from '../DosingInfo/DosingInfo';
import PatientInfo from '../PatientInfo/PatientInfo';
import PrescriptionForm from '../PrescriptionForm/PrescriptionForm';
import FormService from '../../services/Form';

interface XyremXywavFormProps {
  form: FormService;
}

function XyremXywavForm({ form }: XyremXywavFormProps) {
  const id = form?.sectionId;

  const renderForm = () => {
    switch (id) {
      case 0:
        return <PatientInfo />;
      case 1:
        return <DosingInfo />;
      case 2:
        return <PrescriptionForm />;
      default:
        break;
    }
  };

  return <>{renderForm()}</>;
}

export default XyremXywavForm;
