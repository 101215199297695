import SingleProgress from './singleProgress/SingleProgress';

type Step = {
  label: string;
  id: number;
};
interface ProgressBarProps {
  steps: Step[];
  activeStep: number;
}

const ProgressBar = ({ steps, activeStep }: ProgressBarProps) => {
  const displayProgress = steps.map((item) => {
    return (
      <SingleProgress
        item={item}
        key={item.id}
        activeStep={activeStep}
      />
    );
  });

  return (
    <div className="container">
      <div className="row text-center my-3 small">{displayProgress}</div>
    </div>
  );
};

export default ProgressBar;
