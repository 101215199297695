import { createAsyncThunk } from '@reduxjs/toolkit';
import WorkflowService from 'services/Workflow';
import { WorkflowService as ResourceWorkflowService } from 'services/Resources';
import { reducerWorkflowActions } from './workflow.reducer';
import type { AppDispatch, RootState } from '../store';
import type { Workflow, actionInput } from './workflow.reducer';
import * as types from './workflow.types';
import { workflowActions } from 'state/workflow';

interface ResponseRemsInterface {
  workflow: string;
  workflow_step_template: string;
  steps: string;
  status: string;
  uuid: string;
  url: string;
  created_at: string;
  modified_at: string;
  enabled: boolean;
}

export const initWorkflow = createAsyncThunk(
  types.WORKFLOW_LOAD,
  (payload: Workflow, { dispatch }) => {
    const workflow = new WorkflowService(payload);
    dispatch(reducerWorkflowActions.loadWorkflow(workflow));
    workflow.init(dispatch);
  },
);

export const workflowNextStep =
  (actionInput?: actionInput) => (dispatch: AppDispatch, getState: () => RootState) => {
    dispatch({ type: types.WORKFLOW_NEXT });
    const { workflow } = getState().workflow;

    if (workflow) {
      if (actionInput) {
        workflow.setActionInput(actionInput);
      }
      workflow.nextStep();
    }
  };

export const workflowRetryLatestActionStep =
  (actionInput?: actionInput) => (dispatch: AppDispatch, getState: () => RootState) => {
    dispatch({ type: types.WORKFLOW_RETRY });
    const { workflow } = getState().workflow;

    if (workflow) {
      if (actionInput) {
        workflow.setActionInput(actionInput);
      }
      workflow.retryLatestActionStep();
    }
  };

export const workflowPreviousStep = () => (dispatch: AppDispatch, getState: () => RootState) => {
  dispatch({ type: types.WORKFLOW_PREV });
  const { workflow } = getState().workflow;

  if (workflow) {
    workflow.prevStep();
  }

  dispatch(workflowActions.setLoading(false));
};

export const initializeWorkflow = createAsyncThunk(
  types.WORKFLOW_INIT,
  (workflowName: string, { dispatch }) => {
    const loadWorkflow = (response: Workflow) => {
      dispatch(initWorkflow(response));
    };

    const onError = (e: unknown) => {
      dispatch(workflowActions.setError(e));
    };

    const initializeWorkflow = (response: ResponseRemsInterface) => {
      ResourceWorkflowService.post(
        {
          params: {
            id: response.uuid,
            action: 'initialize',
          },
        },
        loadWorkflow,
        onError,
      );
    };

    ResourceWorkflowService.post(
      {
        data: {
          workflow_template_slug: workflowName,
        },
      },
      initializeWorkflow,
      onError,
    );
  },
);
