import DosingRow from 'components/DosingRow/DosingRow';

const NarcoFixedDosing = () => {
  return (
    <div
      className="tab-pane"
      id="pills-fixed-1"
      role="tabpanel"
      aria-labelledby="pills-fixed-tab-1"
    >
      <h5 className="pt-3 text-info text-center">Fixed XYWAV Dosing</h5>
      <h6 className="fw-bold">2 times a night dosing (For Narcolepsy and IH)</h6>
      <DosingRow
        fields={{
          doses: ['rems:fixedDosing:nih:dose:1', 'rems:fixedDosing:nih:dose:2'],
          totalDose: 'rems:fixedDosing:nih:dose:total',
        }}
        withOperators
      />
    </div>
  );
};

export default NarcoFixedDosing;
