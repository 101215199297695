import { useAppSelector } from 'hooks/useRedux';
import { workflowSelectors } from 'state/workflow';
import ErrorComponent from 'components/ErrorComponent/ErrorComponent';
import {
  ERROR_EPCS_INVITE_FAILED,
  ERROR_INVALID_EPCS,
  ERROR_EPCS_IDP_FAILED,
} from 'constants/errorCodes';

type wrapperType = 'patient' | 'xyrem' | 'xywav' | 'prescription' | 'provider';

interface FormWrapperProps {
  children?: React.ReactNode;
  text?: string;
  type?: wrapperType;
  disableError?: boolean;
  disableReturn?: boolean;
  customError?: string;
  errorSubTitle?: string;
  disableButton?: boolean;
}

const handleBackgroundColor = (type: wrapperType | undefined) => {
  switch (type) {
    case 'patient':
      return 'bg-warning';
    case 'xyrem':
      return 'bg-xyrem';
    case 'xywav':
      return 'bg-xywav';
    case 'prescription':
      return 'bg-secondary';
    case 'provider':
      return 'bg-dark';
    default:
      return 'bg-info';
  }
};

const errorCodes = [ERROR_EPCS_INVITE_FAILED, ERROR_INVALID_EPCS, ERROR_EPCS_IDP_FAILED];

const FormWrapper = ({
  customError,
  children,
  text = '',
  type,
  disableError = false,
  disableReturn,
  errorSubTitle,
  disableButton,
}: FormWrapperProps) => {
  const error = useAppSelector(workflowSelectors.errorSelector);

  const errorCode = !Array.isArray(error) && typeof error === 'object' && error?.code;

  const setDisableButton = disableButton || (errorCode ? errorCodes.includes(errorCode) : false);
  const setErrorBgcColor = error && !disableError ? 'prescription' : type;
  const backgroundColor = handleBackgroundColor(setErrorBgcColor);

  return (
    <div className="col-12">
      <div className="card mb-2">
        <div className={`card-header ${backgroundColor} text-white fw-bolder`}>{text}</div>
        {error && !disableError ? (
          <ErrorComponent
            disableButton={setDisableButton}
            customError={customError}
            disableReturn={disableReturn}
            errorSubTitle={errorSubTitle}
          />
        ) : (
          children
        )}
      </div>
    </div>
  );
};

export default FormWrapper;
