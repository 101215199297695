export const setTotal = (doses: string[]): string => {
  const setNumbers = doses.reduce((acc, dose) => {
    const number = parseFloat(dose);

    if (isNaN(number)) {
      return acc;
    }

    return acc + number;
  }, 0);

  if (setNumbers) {
    return (+setNumbers).toFixed(3).replace(/\.0*$|(\.\d*[1-9])0+$/, '$1');
  }

  return '';
};
