import { createSlice } from '@reduxjs/toolkit';

interface LanguageState {
  selectedLanguage: string;
}

export const initialState: LanguageState = {
  selectedLanguage: 'eng',
};

const languageSlice = createSlice({
  name: 'language',
  initialState,
  reducers: {
    setLanguage(state, action) {
      state.selectedLanguage = action.payload;
    },
  },
});

export const reducerLanguageActions = languageSlice.actions;
export default languageSlice.reducer;
