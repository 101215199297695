import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  ForgotPasswordService,
  LoginService,
  LogoutService,
  RegisterService,
  ResetPasswordService,
  UserService,
  WorkflowService,
} from 'services/Resources';
import { authActions } from './index';
import { workflowActions } from '../workflow';
import * as types from './auth.types';
import type { AppDispatch } from '../store';
import { formsActions } from '../forms';

interface LoginServiceResponse {
  access_token: string;
  refresh_token: string;
}

export const loggingIn = createAsyncThunk(
  types.LOGGING_IN,
  async (payload: { [key: string]: string }, { dispatch }) => {
    const formData = {
      ...payload,
      grant_type: payload.username ? 'password' : 'refresh_token',
      client_id: process.env.REACT_APP_CLIENT_ID,
    };
    const data = (Object.keys(formData) as Array<keyof typeof formData>)
      .map((key) => `${key}=${encodeURIComponent(formData[key] as string)}`)
      .join('&');

    const options = {
      params: {
        enabled: undefined,
      },
      withCredentials: true,
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      data,
    };

    const storageAccessToken = sessionStorage.getItem('access_token');
    if (storageAccessToken) {
      dispatch(
        authActions.login({
          access_token: storageAccessToken,
        }),
      );

      return;
    }

    await LoginService.post(
      options,
      async (res: LoginServiceResponse) => {
        await UserService.get(
          {
            params: {
              enabled: undefined,
            },
            headers: {
              Authorization: `Bearer ${res.access_token}`,
            },
          },
          (user: any) => {
            const epcsStatus = user?.epcs?.epcs_status;
            const grantStatus = user?.epcs?.grant_status;

            if (
              typeof epcsStatus !== 'undefined' &&
              typeof grantStatus !== 'undefined' &&
              (epcsStatus !== 'ENROLLED' || grantStatus !== 'ACTIVE')
            ) {
              const newTab = window.open(window.location.origin + '/sso', '_blank'); //open in new tab

              newTab?.addEventListener('rems_sso_init', () => {
                newTab?.postMessage(
                  {
                    token: res.access_token,
                  },
                  '*',
                );
              });

              setTimeout(function () {
                try {
                  if (!dispatch || !authActions || typeof authActions !== 'object') return;

                  if (newTab === null || newTab.closed || newTab.closed === undefined) {
                    dispatch(authActions.setNewTabStatus('ERROR'));
                  } else {
                    dispatch(authActions.setNewTabStatus('SUCCESS'));
                  }
                } catch (e) {
                  console.log('Error in opening new tab');
                  console.log(e);
                }
              }, 200);
            } else {
              sessionStorage.setItem('access_token', res.access_token);

              dispatch(authActions.login(res));
            }
          },
          () => {
            dispatch(authActions.loggingFailed());
            dispatch(workflowActions.reset());
          },
        );
      },
      () => {
        if (payload.username && payload.password) {
          dispatch(authActions.setWrongCredentials(true));
        }

        dispatch(authActions.loggingFailed());
        dispatch(workflowActions.reset());
      },
    );
  },
);

export const logOutAndRemoveRefreshToken = createAsyncThunk(
  types.LOGGING_OUT,
  async (payload: object, { dispatch }) => {
    sessionStorage.removeItem('access_token');

    const formData = {
      ...payload,
      grant_type: 'refresh_token',
      client_id: process.env.REACT_APP_CLIENT_ID,
    };

    const data = (Object.keys(formData) as Array<keyof typeof formData>)
      .map((key) => `${key}=${encodeURIComponent(formData[key] as string)}`)
      .join('&');

    const options = {
      params: {
        enabled: undefined,
      },
      withCredentials: true,
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      data,
    };

    await LogoutService.post(
      options,
      () => {
        dispatch(formsActions.resetValues());
        dispatch(authActions.logout());
        dispatch(workflowActions.reset());
      },
      (err: object) => {
        console.log(err);
      },
    );
  },
);

export const loggingOut = () => (dispatch: AppDispatch) => {
  dispatch({ type: types.LOGGING_OUT });
  dispatch(authActions.logout());
  dispatch(workflowActions.reset());
};

export const initRegister = createAsyncThunk(types.REGISTER, async (_, { dispatch }) => {
  const options = {
    params: {
      enabled: undefined,
    },
  };

  await RegisterService.post(
    options,
    (res: any) => {
      dispatch(authActions.login({ access_token: res.token }));
      WorkflowService.post(
        {
          params: {
            id: res.uuid,
            action: 'initialize',
          },
        },
        (response: any) => {
          dispatch(workflowActions.initWorkflow(response));
        },
        (err: any) => {
          dispatch(authActions.loggingFailed());
        },
      );
    },
    () => {
      dispatch(authActions.loggingFailed());
    },
  );
});

export const forgotPassword = createAsyncThunk(
  types.FORGOT_PASSWORD,
  async (payload: { [key: string]: string }, { dispatch }) => {
    const formData = {
      email: payload.email,
    };

    const options = {
      data: formData,
      params: {
        enabled: undefined,
      },
      headers: {
        'Content-Type': 'application/json',
      },
    };

    await ForgotPasswordService.post(
      options,
      () => {
        dispatch(authActions.setForgotPasswordError(''));
      },
      () => {
        dispatch(authActions.setForgotPasswordError('Something went wrong'));
      },
    );
  },
);

export const resetPassword = createAsyncThunk(
  types.RESET_PASSWORD,
  async (payload: { [key: string]: string }, { dispatch }) => {
    const formData = {
      password: payload.password,
      token: payload.token,
    };

    await ResetPasswordService.post(
      {
        data: formData,
        params: {
          enabled: undefined,
        },
        headers: {
          'Content-Type': 'application/json',
        },
      },
      () => {
        dispatch(authActions.setResetPasswordError(''));
      },
      (err: any) => {
        switch (err.status) {
          case 404:
            dispatch(authActions.setResetPasswordError('Token has expired or is invalid'));
            break;
          case 400:
            if (err.data.password) {
              dispatch(authActions.setResetPasswordError(err.data.password));
            } else {
              dispatch(authActions.setResetPasswordError('Something went wrong'));
            }
            break;
          default:
            dispatch(authActions.setResetPasswordError('Something went wrong'));
            break;
        }
      },
    );
  },
);
