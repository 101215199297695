import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import './Loader.scss';

interface LoaderProps {
  type?: string;
}

const Loader = ({ type }: LoaderProps) => {
  return (
    <div className={`loader-container loader-${type || 'main'}`}>
      <div>
        <FontAwesomeIcon
          icon={faSpinner}
          color="black"
          spinPulse={true}
        />
      </div>
    </div>
  );
};

export default Loader;
