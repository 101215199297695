import { createSelector } from 'reselect';
import type { RootState } from '../store';
import { REMS_XYREM, REMS_XYWAV } from 'constants/identifierAndActions';

export const workflowSelector = (state: RootState) => state.workflow;
export const stepSelector = (state: RootState) => state.workflow.step;
export const loadingSelector = (state: RootState) => state.workflow.isLoading;
export const errorSelector = (state: RootState) => state.workflow.error;
export const workflowForm = (state: RootState) => state.workflow.workflow?.form;
export const formsSelector = (state: RootState) => state.workflow.workflow?.forms;
export const workflowStepsSelector = (state: RootState) => state.workflow.workflow?.steps;
export const lastStepBeforeErrorCloseSelector = (state: RootState) =>
  state.workflow.workflow?.lastStepBeforeErrorClose;

export const formValuesSelector = createSelector(workflowForm, (form) => {
  if (form && form.values) {
    return form.values;
  }
});

export const locationDefaultSelector = createSelector(workflowForm, (form) => {
  const location = form?.section?.fields['provider:location'];
  return location?.choices?.defaultOption;
});

export const remsFormValuesSelector = createSelector(formsSelector, (forms) => {
  if (forms) {
    return Object.values(forms).find((form) => {
      if (form.identifier === REMS_XYWAV || form.identifier === REMS_XYREM) {
        return form.values;
      }
    });
  }
});

export const lastStepActionNameSelector = createSelector(workflowStepsSelector, (steps) => {
  if (steps) {
    return steps[steps.length - 1].action_name;
  }
});

export const prescriptionLoadingSelector = createSelector(
  loadingSelector,
  lastStepBeforeErrorCloseSelector,
  lastStepActionNameSelector,
  (isLoading, lastStepBeforeError, lastStep) => {
    if (isLoading || (lastStepBeforeError !== 'submit_forms' && lastStepBeforeError === lastStep)) {
      return true;
    }

    return false;
  },
);
