export const LOGGING_IN = 'AUTH/LOGGING_IN';
export const LOGGING_OUT = 'AUTH/LOGGING_OUT';
export const REGISTER = 'AUTH/REGISTER';
export const FORGOT_PASSWORD = 'AUTH/FORGOT_PASSWORD';
export const RESET_PASSWORD = 'AUTH/RESET_PASSWORD';

/**
 * null - there was no attempt to open a new tab
 * "SUCCESS" - the new tab was opened successfully
 * "ERROR" - there was an error opening the new tab
 */
export type NewTabStatus = null | 'SUCCESS' | 'ERROR';
