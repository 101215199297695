/* eslint-disable @typescript-eslint/ban-ts-comment */
// @ts-nocheck
import FormService from './Form';
import { WorkflowStepService, WorkflowFormService } from './Resources';
import { forkJoin, of } from 'rxjs';
import { workflowActions } from '../state/workflow/index';
import store from '../state/store';

export default class WorkflowService {
  constructor(workflow) {
    this.workflow = workflow;
    this.steps = null;
    this.uuid = null;
    this.displayStep = null;
    this.step = null;
    this.stepId = 0;
    this.forms = {};
    this.prevButtonDisabled = false;
    this.tmpStepId = 0;
    this.actionInput = null;
  }

  init(dispatch) {
    this.dispatch = dispatch;
    this.updateWorkflow(this.workflow, this.nextStep);
  }

  updateWorkflow(workflow, callback) {
    this.steps = JSON.parse(workflow.steps);
    this.uuid = workflow.uuid;
    this.status = workflow.status;
    this.collectResources().subscribe(callback.bind(this));
  }

  collectResources() {
    const promises = [];

    for (const step of this.steps) {
      if (step.type === 'form') {
        const formId = step.id;
        if (!this.forms[formId]) {
          const promise = this.getForm(formId);
          promises.push(promise);
        }
      }
    }

    if (promises.length > 0) {
      return forkJoin(promises);
    } else {
      return of(promises);
    }
  }

  getForm = (formId) => {
    return WorkflowFormService.get(
      {
        params: {
          id: formId,
        },
      },
      this.setForm,
    );
  };

  setForm = (response) => {
    this.forms[response.uuid] = new FormService(response);
  };

  retryLatestActionStep() {
    for (let i = this.steps.length - 1; i >= 0; i--) {
      const step = this.steps[i];
      if (step.type === 'action') {
        this.performAction(step);
        return;
      }
    }
  }

  nextStep() {
    this.tmpStepId = this.stepId;
    if (this.step && this.step.type === 'form') {
      const exist = this.form.isNextSection();
      if (exist) {
        this.disablePrevButton(false);
        this.dispatch(workflowActions.update());
        return;
      }
    }
    const offset = 1;
    try {
      this.getStep(offset);
    } catch (e) {
      this.exitWorkflow();
    }
  }

  isPrevStepAvailable() {
    this.disablePrevButton(false);
    if (this.getForm.prevSection) {
      const prevSection = this.form.prevSection();
      if (prevSection) {
        alert('test');
        this.dispatch(workflowActions.update());
        return true;
      }
      const offset = -2;
      const prevStepData = this.steps[this.stepId + offset];
      if (prevStepData === undefined) {
        console.log('Previous step is unavailable');
        this.disablePrevButton(true);
        return false;
      } else if (prevStepData && prevStepData.type === 'action') {
        console.log('Previous step is action, you cant go back');
        this.disablePrevButton(true);
        return false;
      }
      return true;
    }
  }

  prevStep() {
    if (this.step && this.step.type === 'form') {
      const exist = this.form.isPrevSection();
      if (exist) {
        this.dispatch(workflowActions.update());
        return;
      }
    }
    const offset = -1;
    try {
      this.getStep(offset);
    } catch (e) {
      this.exitWorkflow();
    }
  }

  disablePrevButton(value) {
    this.prevButtonDisabled = value;
  }

  getStep(offset) {
    const stepId = this.stepId + offset;
    const step = this.steps[stepId - 1];

    if (!step) {
      throw new Error('Step not available');
    } else {
      this.changeStep(step, stepId);
    }
  }

  changeStep(step, stepId) {
    this.stepId = stepId;
    this.step = step;
    if (step.type === 'action') {
      this.performAction(step);
    } else {
      this.displayStep = step;
      this.form = this.forms[step.id];
      this.isPrevStepAvailable();
      this.dispatch(workflowActions.update());
    }
  }

  getStepByIdentifier(identifier) {
    for (let i = this.step; i < this.workflow.steps.length; ++i) {
      const step = this.workflow.steps[i];
      if (step.identifier === identifier) {
        this.changeStep(step, i + 1);
      }
    }
  }

  getValueById(id) {
    let forms = Object.values(this.forms);
    forms = forms.map((form) => form.values);
    forms = Object.assign({}, ...forms);
    return forms[id];
  }

  exitWorkflow() {
    console.log('exit workflow');
  }

  completeAction = (response) => {
    this.updateWorkflow(response, this.nextStep);
  };

  actionError = () => {
    //this.stepId = this.tmpStepId;
    // Needed to prevent infinite loading on error in lac
    store.dispatch(workflowActions.setLoading(false));
  };

  getSubmittableForms() {
    const data = {};
    for (const formId in this.forms) {
      const form = this.forms[formId];
      data[form.uuid] = form.getValues();
    }
    return data;
  }

  performAction(step) {
    let data = step.data;

    const actionName = step.action_name;
    const actionMethod = step.method;

    if (actionMethod === 'change_workflow') {
      this.switchWorkflow(data);
      return;
    }

    if (actionMethod === 'reset_workflow') {
      // ToDo Integrate with Alek's REM-296 changes for now just redirect
      window.location.href = '/';
      return;
    }

    if (data === 'forms') {
      data = this.getSubmittableForms();
    } else if ((!data || !data.length) && this.actionInput) {
      data = this.actionInput;
    }

    if (actionName !== 'submit_forms') {
      store.dispatch(workflowActions.setLoading(true));
    }

    store.dispatch(workflowActions.resetWorkflowStatus());

    WorkflowStepService[actionMethod](
      {
        params: {
          id: this.uuid,
          action: 'action',
          actionId: actionName,
        },
        data: data,
      },
      this.completeAction,
      this.actionError,
    );
  }

  switchWorkflow(data) {
    WorkflowStepService.get(
      {
        params: {
          id: data,
        },
      },
      this.dispatchWorkflow,
    );
  }

  dispatchWorkflow = (response) => {
    this.dispatch(workflowActions.initWorkflow(response));
  };

  setActionInput = (actionInput) => {
    this.actionInput = actionInput;
  };
}
