/* eslint-disable @typescript-eslint/ban-ts-comment */

import { FormTypeInterface, FieldInterfaceWrapped, FieldInterface } from '../../models/models';
import { emailRegex } from '../constants/validationRules';
import store from '../state/store';

export default class FormService {
  template: { sections: Array<FieldInterfaceWrapped> };
  identifier: string;
  uuid: string;
  values: { [key: string]: string | FieldInterface };
  sections: Array<FieldInterfaceWrapped>;
  sectionId: number;
  section: FieldInterfaceWrapped;
  afterSectionsId: number;

  constructor(form: FormTypeInterface) {
    this.template =
      JSON.parse(form.form_template) ??
      JSON.parse(
        '{\r\n\t"sections": [\r\n\t\t{\r\n\t\t\t"fields": [\r\n\t\t\t\t{\r\n\t\t\t\t\t\r\n\t\t\t\t}\r\n\t\t\t]\r\n\t\t}\r\n\t]\r\n}"',
      );
    this.identifier = form.identifier ?? '';
    this.uuid = form.uuid || '';
    this.values = JSON.parse(form.values) ?? JSON.parse("{ '': [] }");
    this.sections = this.getSections();
    this.sectionId = 0;
    this.afterSectionsId = 0;
    this.section = this.template.sections[0];
  }

  getSections(): FieldInterfaceWrapped[] {
    if (this.template?.sections) {
      for (const section of this.template.sections) {
        section.fields = this.getFields(section);
      }

      return this.template.sections;
    }
    return [];
  }

  getSection(idx: number): FieldInterfaceWrapped {
    this.getValues();
    return this.sections[idx];
  }

  getFields(section: FieldInterfaceWrapped): { [key: string]: FieldInterface } {
    const fields: { [key: string]: FieldInterface } = {};
    if (section.fields) {
      for (const field of Object.values(section.fields)) {
        this.setFieldDefaultValue(field);
        fields[field.identifier] = field;
      }

      return fields;
    }
    return {};
  }

  getAllFields() {
    const fields: { [key: string]: FieldInterface } = {};
    for (const section of this.sections) {
      for (const fieldId in section.fields) {
        const field = section.fields[fieldId];
        fields[field.identifier] = field;
      }
    }
    return fields;
  }

  getAllFieldsByPrefix(prefix: string, valueOnly = false) {
    const fields: { [key: string]: string | object } = {};
    for (const section of this.sections) {
      for (const fieldId in section.fields) {
        const field = section.fields[fieldId];
        if (field.identifier.indexOf(prefix) === 0) {
          fields[field.identifier] = valueOnly ? field.value : field;
        }
      }
    }
    return fields;
  }

  getAllFieldsExceptDummy() {
    const fields: { [key: string]: string | object } = {};
    for (const section of this.sections) {
      for (const fieldId in section.fields) {
        const field = section.fields[fieldId];
        if (field.identifier.indexOf('dummy') === -1) {
          fields[field.identifier] = field.value;
        }
      }
    }
    return fields;
  }

  getFieldByKey(key: string): FieldInterface | undefined {
    for (const section of this.sections) {
      if (section.fields[key]) {
        return section.fields[key];
      }
    }
  }

  setFieldDefaultValue(field: FieldInterface): void {
    try {
      if (this.values[field.identifier] !== undefined) {
        field.value = this.values[field.identifier];

        // @ts-ignore
        if (field.choices) {
          // @ts-ignore
          for (const key in this.values[field.identifier]) {
            // @ts-ignore
            field.choices[key] = this.values[field.identifier][key];
          }
        }
      } else if (field.defaultValue !== undefined) {
        field.value = field.defaultValue;
      } else {
        field.value = '';
      }
    } catch {
      throw 'Default values ​​cannot be set';
    }
  }
  isValidateField({ field }: { field: FieldInterface }): boolean {
    let regexp: RegExp;

    switch (field.validationRule) {
      case 'email':
        regexp = new RegExp(emailRegex);
        break;
      default:
        regexp = new RegExp(field.validationRule);
    }
    if (typeof field.value === 'string') {
      return regexp.test(field.value);
    }
    return false;
  }

  isValidate(): boolean {
    let error = false;
    if (this.section.fields)
      for (const fieldId in this.section.fields) {
        const field = this.section.fields[fieldId];
        const value = field.value;
        if (field.required && (value === undefined || value === '')) {
          field.error = true;
          error = true;
        } else if (value && field.validationRule && !this.isValidateField({ field })) {
          field.error = true;
          error = true;
        } else {
          field.error = false;
        }
      }
    return !error;
  }

  isNextSection(): boolean {
    const section = this.isSectionLookup(1);
    if (section && this.section.display === false) {
      return this.isNextSection();
    }
    return section;
  }

  isPrevSection(): boolean {
    return this.isSectionLookup(-1);
  }

  isSectionLookup(offset: number): boolean {
    if (this.sectionId === 2) {
      this.afterSectionsId = this.sectionId + offset;
    }
    const sectionId = this.sectionId + offset;
    const section = this.template.sections[sectionId];
    if (section) {
      this.sectionId = sectionId;
      this.section = section;
    }
    return !!section;
  }

  getValues(): { [key: string]: string | string[] } {
    const values = store.getState().forms.values[this.uuid];
    const genValues: { [key: string]: string } = {};
    const remainingFieldsValues = this.getRemainingFieldsWithDefaultValuesInFormContent(values);
    const fields = this.getAllFields();
    for (const fieldId in fields) {
      const field = fields[fieldId];
      if (field.valueBuilder) {
        try {
          const interpolatedValue = this.interpolateValue(field.valueBuilder);
          if (interpolatedValue) {
            genValues[fieldId] = interpolatedValue;
          }
        } catch (e) {
          console.log('Cannot interpolate string');
          console.log(e);
        }
      }
    }
    return { ...values, ...genValues, ...remainingFieldsValues };
  }

  getRemainingFieldsWithDefaultValuesInFormContent(values: {
    [key: string]: string | string[] | number;
  }): {
    [key: string]: string;
  } {
    const remainingFieldsValues: { [key: string]: string } = {};

    for (const section of this.sections) {
      for (const [field, fieldValueObject] of Object.entries(section.fields)) {
        if (fieldValueObject.defaultValue && !values[field] && !field.startsWith('dummy')) {
          // @ts-ignore
          remainingFieldsValues[field] = fieldValueObject.defaultValue;
        }
      }
    }
    return remainingFieldsValues;
  }

  getInterpolationChunks(value: string): string[] {
    const found = [], // an array to collect the strings that are found
      rxp = /{([^}]+)}/g;
    let curMatch;

    while ((curMatch = rxp.exec(value))) {
      found.push(curMatch[1]);
    }

    return found;
  }

  interpolateValue(value: string): string | undefined {
    const chunks = this.getInterpolationChunks(value);
    const valueIdentifiersValue = chunks
      .filter((item) => item.endsWith('value'))
      .map((item) => this.getInterpolationParamValue(item));
    if (valueIdentifiersValue.includes('')) return;

    const values = chunks.map((istring: string) => {
      return this.getInterpolationParamValue(istring);
    });

    chunks.forEach((label, index) => {
      value = value.replace(`{${label}}`, values[index] as string);
    });
    return value;
  }

  getInterpolationParamValue(param: string): string | string[] {
    const [identifier, paramName]: string[] = param.split('.');
    const values = store.getState().forms.values[this.uuid];
    switch (paramName) {
      case 'value':
        return values[identifier] || '';
      case 'title':
        // @ts-ignore
        return this.getFieldByKey(identifier)[paramName]['eng'];
      default:
        return '';
    }
  }

  getValuesFieldId(
    section: FieldInterfaceWrapped,
    values: { [key: string]: string | string[] | object },
  ): void {
    for (const fieldId in section.fields) {
      const field = section.fields[fieldId];
      if (values[field.identifier] && !field.value) {
        continue;
      }
      values[field.identifier] = field.value;
    }
  }

  getField(identifier: string, section: FieldInterfaceWrapped): FieldInterface {
    if (section === undefined) {
      section = this.section;
    }
    const field = section.fields[identifier];
    this.setFieldDefaultValue(field);
    return field;
  }
}
