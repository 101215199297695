import XyremTitratedDosing from '../xyrem/xyremTabs/TitratedDosing';
import XyremFixedDosing from '../xyrem/xyremTabs/FixedDosing';
import XywavFixedDosing from '../xywav/xywavTabs/FixedDosing';
import XywavNarcoFixedDosing from '../xywav/xywavTabs/NarcoFixedDosing';
import XywavTitratedDosing from '../xywav/xywavTabs/TitratedDosing';
import XywavNarcoTitratedDosing from '../xywav/xywavTabs/NarcoTitratedDosing';

interface TabFormProps {
  serviceType?: string;
  tabValue: number;
}

const TabForm = ({ serviceType, tabValue }: TabFormProps) => {
  const setTabs = () => {
    if (serviceType === 'xyrem') {
      switch (tabValue) {
        case 1:
          return <XyremTitratedDosing />;
        case 2:
          return <XyremFixedDosing />;

        default:
          null;
      }
    }

    if (serviceType === 'xywav') {
      switch (tabValue) {
        case 1:
          return <XywavNarcoTitratedDosing />;
        case 2:
          return <XywavTitratedDosing />;
        case 3:
          return <XywavNarcoFixedDosing />;
        case 4:
          return <XywavFixedDosing />;

        default:
          null;
      }
    }
  };

  return <>{setTabs()}</>;
};

export default TabForm;
