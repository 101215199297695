import React from 'react';

type Props = {
  children: React.ReactNode;
  bgColor?: string;
  className?: string;
};
const Wrapper = ({ children, bgColor, className }: Props) => {
  return (
    <div
      className={'container-fluid rounded-4 p-2 p-md-3 ' + className}
      style={{ backgroundColor: bgColor }}
    >
      {children}
    </div>
  );
};

export default Wrapper;
