import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAppSelector } from 'hooks/useRedux';
import { serviceTypeSelectors } from 'state/serviceType/index';
import ProviderInformation from 'components/ProviderInformation/ProviderInformation';
import NameHeader from 'components/nameHeader/NameHeader';
import ProgressBar from 'components/Progress/ProgressBar';
import ErrorComponent from 'components/ErrorComponent/ErrorComponent';
import FormWrapper from 'components/FormWrapper/FormWrapper';
import LoadingContent from 'components/LoadingContent/LoadingContent';
import RcopiaLoginForm from 'forms/RcopiaLoginForm/RcopiaLoginForm';
import XyremXywavForm from 'forms/XyremXywavForm/XyremXywavForm';
import UnsignedPrescription from 'forms/UnsignedPrescription/UnsignedPrescription';
import CompleteWorkflow from 'forms/CompleteWorkflow/CompleteWorkflow';
import { progressBarSelectors } from 'state/progressBar';
import { workflowSelectors } from 'state/workflow';
import {
  RCOPIA_LOGIN,
  REMS_COMPLETED,
  REMS_UNSIGNED_PRESCRIPTION,
  REMS_XYREM,
  REMS_XYWAV,
} from 'constants/identifierAndActions';

const getWindowSize = () => {
  const { innerWidth, innerHeight } = window;
  return { innerWidth, innerHeight };
};

function Prescription() {
  const navigate = useNavigate();
  const location = useLocation();

  const identifier = location.state?.identifier;

  const serviceType = useAppSelector(serviceTypeSelectors.unreadServiceTypeSelector);
  const step = useAppSelector(progressBarSelectors.stepSelector);
  const form = useAppSelector(workflowSelectors.workflowForm);
  const prescriptionLoadingSelector = useAppSelector(workflowSelectors.prescriptionLoadingSelector);

  const [windowSize, setWindowSize] = useState(getWindowSize());

  useEffect(() => {
    const handleWindowResize = () => {
      setWindowSize(getWindowSize());
    };
    window.addEventListener('resize', handleWindowResize);

    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  }, []);

  const smallScreenSupport = windowSize.innerWidth >= 1200 ? 'container' : 'container-fluid';

  if (!identifier) {
    navigate('/');
    return null;
  }

  const renderForm = () => {
    switch (identifier) {
      case REMS_XYWAV:
      case REMS_XYREM:
        return <XyremXywavForm form={form} />;
      case RCOPIA_LOGIN:
        return (
          <RcopiaLoginForm
            form={form}
            text="Complete Prescription"
          />
        );
      case REMS_UNSIGNED_PRESCRIPTION:
        return <UnsignedPrescription />;
      case REMS_COMPLETED:
        return <CompleteWorkflow />;
      default:
        return <ErrorComponent />;
    }
  };

  return (
    <>
      <NameHeader
        serviceType={serviceType}
        signOut
        remsFlow={true}
      />
      <ProviderInformation />
      <ProgressBar
        steps={[
          { id: 0, label: 'Patient Information' },
          { id: 1, label: 'Dosing Information' },
          { id: 2, label: 'Prescriber Verification' },
          { id: 3, label: 'Complete Prescription' },
        ]}
        activeStep={step}
      />
      <div className={`${smallScreenSupport}`}>
        {prescriptionLoadingSelector ? (
          <FormWrapper text="Complete Prescription">
            <LoadingContent />
          </FormWrapper>
        ) : (
          renderForm()
        )}
      </div>
    </>
  );
}

export default Prescription;
