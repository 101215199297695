import { faEquals, faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import FormField from 'containers/FormField/FormField';
import { setTotal } from 'forms/DosingInfo/helpers/setTotal';
import { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';

interface Props {
  fields: {
    doses: string[];
    totalDose?: string;
    timeOfDrugTaking?: string;
  };
  title?: string;
  withOperators?: boolean;
  wideField?: number;
}
const DosingRow = ({ fields, title, withOperators, wideField }: Props) => {
  const { setValue, getValues, clearErrors, watch, trigger } = useFormContext();
  const [required, setRequired] = useState(false);
  const { doses, totalDose, timeOfDrugTaking } = fields;
  const dosesWatchers = watch(doses);
  const totalWatcher = totalDose ? watch(totalDose) : '';
  const timeWatcher = timeOfDrugTaking ? watch(timeOfDrugTaking) : '';

  useEffect(() => {
    if (totalDose) {
      setValue(totalDose, setTotal(getValues(doses)));
      trigger(totalDose);
    }
  }, [...dosesWatchers, totalWatcher, timeWatcher]);

  useEffect(() => {
    const setDosingRowRequired = async () => {
      const arr = [...doses];
      if (totalDose) {
        arr.push(totalDose);
      }
      if (timeOfDrugTaking) {
        arr.push(timeOfDrugTaking);
      }

      if (getValues(arr).find((field) => field !== '')) {
        setRequired(true);
      }
      if (getValues(arr).every((field) => field === '')) {
        if (required) {
          await trigger(arr);
          clearErrors(arr);
        }
        setRequired(false);
      }
    };
    setDosingRowRequired();
  }, [...dosesWatchers, totalWatcher, timeWatcher]);

  return (
    <div className="row g-0 py-2">
      {title && <div className="col-2 pt-4">{title}</div>}
      {doses.map((field, index) => {
        return (
          <div
            key={field}
            data-testid="containerForNumberField"
            className={`${wideField == index ? 'col-3' : 'col-2'} d-flex`}
          >
            <div className="input-group input-group-sm">
              <FormField
                type="text"
                variant={'number'}
                units="g"
                identifier={field}
                required={required}
                hideReq={required}
              />
            </div>
            {withOperators && index != doses.length - 1 && (
              <div
                data-testid="operator"
                className="float-end px-3 mb-2 align-self-end"
              >
                <FontAwesomeIcon
                  icon={faPlus}
                  color="black"
                />
              </div>
            )}
            {withOperators && index == doses.length - 1 && (
              <div
                data-testid="operator"
                className="float-end px-3 mb-2 align-self-end"
              >
                <FontAwesomeIcon
                  icon={faEquals}
                  color="black"
                />
              </div>
            )}
          </div>
        );
      })}
      {totalDose && (
        <div
          data-testid="containerForNumberField"
          className={`${wideField ? 'col-3' : 'col-2'} d-flex`}
        >
          <div className="input-group input-group-sm">
            <FormField
              type="text"
              variant={'numberAsText'}
              readOnly={true}
              units="g"
              identifier={totalDose}
              required={required}
              hideReq={required}
            />
          </div>
        </div>
      )}
      {timeOfDrugTaking && (
        <div className="col-2 offset-1 d-flex">
          <div className="input-group input-group-sm">
            <FormField
              identifier={timeOfDrugTaking}
              step={1}
              wholeNumber
              type="text"
              variant="number"
              units="days"
              required={required}
              hideReq={required}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default DosingRow;
