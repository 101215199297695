import { useAppDispatch } from './useRedux';
import { formsActions } from '../state/forms/index';
import { workflowActions } from '../state/workflow/index';
import { progressBarActions } from '../state/progressBar';

const moveStep = () => {
  const dispatch = useAppDispatch();

  const updateCorrectState = (formId: string, newState: object) => {
    dispatch(formsActions.setFormValues({ formId, values: newState }));
  };

  const onNextClick = (formId: string, state: object, nextStep = true) => {
    updateCorrectState(formId, state);
    dispatch(workflowActions.workflowNextStep());
    if (nextStep) {
      dispatch(progressBarActions.nextStep());
    }
  };

  const onPrevClick = (formId: string, state: object, nextStep = true) => {
    updateCorrectState(formId, state);
    dispatch(workflowActions.workflowPreviousStep());
    if (nextStep) {
      dispatch(progressBarActions.prevStep());
    }
  };

  return {
    onNextClick,
    onPrevClick,
  };
};

export default moveStep;
